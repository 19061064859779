import { TFunction } from "i18next";
import { StylesConfig } from "react-select/dist/declarations/src/styles";
import { GroupBase } from "react-select/dist/declarations/src/types";
import { SelectItem, TSelectSize } from "../../index";
import simulationEmpty from "../../../images/simulation-empty-second.svg";
import iconCheckBlue from "../../../images/icons/icon-check-blue.svg";

interface ISelectStylesPayload {
  canSearch?: boolean;
  forDarkBg?: boolean;
  error?: boolean;
  hasImage?: boolean;
  hideBorder?: boolean;
  placeholder?: string;
  t: TFunction<"translation">;
  required?: boolean;
  isSessionTable?: boolean;
  isLaptop: boolean;
  activeItemImage?: string;
  isTopPosition?: boolean;
  label?: string;
  forTable?: boolean;
  isSearchValue?: boolean;
  size?: TSelectSize;
}

export const selectStyles = (
  payload: ISelectStylesPayload,
): StylesConfig<SelectItem, false | true, GroupBase<SelectItem>> => {
  const {
    canSearch,
    forDarkBg,
    error,
    hasImage,
    hideBorder,
    placeholder,
    t,
    required,
    isSessionTable,
    isLaptop,
    activeItemImage,
    label,
    forTable,
    isSearchValue,
    size,
  } = payload;

  const isSmall: boolean = size === "small";
  const isMedium: boolean = size === "medium";

  return {
    placeholder: () => ({
      display: "none",
    }),
    control: (_, state) => {
      const { hasValue, isFocused, isDisabled } = state;
      const isCollapsed: boolean = hasValue || (isFocused && !!canSearch);
      const borderColor = (): string => {
        if (isFocused) {
          if (forDarkBg) {
            return "var(--white)";
          }

          return "var(--dark-blue)";
        }

        if (forDarkBg) {
          return "var(--dark-gray)";
        }

        if (error) {
          return "var(--red)";
        }

        if (isDisabled) {
          return "var(--extra-light-purple)";
        }

        return "var(--extra-light-gray)";
      };
      const background = (): string => {
        if (hideBorder || forDarkBg || forTable) {
          return "transparent";
        }

        return "var(--white)";
      };
      const localPlaceholder = (): string | undefined => {
        if (hasImage && hasValue) {
          return undefined;
        }

        if (hasImage) {
          return `"${t("common.select.notSelected")}${required ? "*" : ""}"`;
        }

        if (placeholder) {
          return `"${placeholder}${required ? "*" : ""}"`;
        }
      };
      const beforeTransform = (): string | undefined => {
        if (isCollapsed) {
          if (size) {
            return "translateX(-1rem)";
          }

          return "translateY(-1rem)";
        }

        return undefined;
      };
      const height = () => {
        if (forTable) {
          return "2rem";
        }
        if (size) {
          return `var(--field-height-${size})`;
        }

        return "var(--field-height)";
      };
      const placeholderTop = () => {
        if (isSmall) {
          return "0.4rem";
        }

        if (isMedium) {
          return "0.8rem";
        }

        return "1.8rem";
      };

      return {
        height: height(),
        background: background(),
        border:
          !hideBorder && !forTable
            ? "0.1rem solid var(--extra-light-gray)"
            : "inherit",
        borderRadius: "var(--border-radius-small)",
        display: "flex",
        position: "relative",
        overflow: "hidden",
        borderColor: borderColor(),

        "::before": !!localPlaceholder()
          ? {
              content: localPlaceholder(),
              position: "absolute",
              top: !forTable ? placeholderTop() : undefined,
              left: !forTable ? "1.2rem" : undefined,
              fontSize: forTable
                ? "1.6rem"
                : isCollapsed && !size
                ? "1.2rem"
                : "1.4rem",
              lineHeight: isCollapsed && !size ? "1.6rem" : "1.9rem",
              opacity: isCollapsed && size ? 0 : undefined,
              color: "var(--gray)",
              transform: beforeTransform(),
              transition: "0.3s",
              width: "100%",
              maxWidth: "calc(100% - 4.7rem)",
              margin: 0,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }
          : undefined,
      };
    },
    valueContainer: (_, state) => {
      const { isDisabled, hasValue } = state;
      const fontSize = (): string => {
        if (isSessionTable && isLaptop) {
          return "1.6rem";
        }
        if (forTable) {
          return "1.6rem";
        }

        return "1.4rem";
      };
      const padding = (): string => {
        if (forTable) {
          return "0";
        }

        if (isMedium) {
          return "0.8rem 0 0.8rem 1.2rem";
        }

        if (isSmall) {
          return "0.5rem 0 0.5rem 1rem";
        }

        if (hasImage) {
          return "0 0 0 1.2rem";
        }

        return "2.8rem 0 0 1.2rem";
      };
      const color = (): string => {
        if (forDarkBg) {
          return "var(--white)";
        }

        if (isDisabled) {
          return "var(--gray)";
        }

        return "var(--black)";
      };

      return {
        padding: padding(),
        fontSize: fontSize(),
        lineHeight: "1.8rem",
        color: color(),
        flex: 1,
        position: "relative",
        overflow: "hidden",
        paddingTop: !placeholder && !size && !forTable ? "1.8rem" : undefined,
        paddingBottom:
          !placeholder && !size && !forTable ? "1.8rem" : undefined,
        cursor: "pointer",
        display: "flex",
        alignItems: hasImage ? "center" : undefined,
        textDecoration: forTable ? "underline" : "none",
        ":hover": forTable
          ? {
              color: "#283a97",
            }
          : undefined,
        "::before": hasValue
          ? {
              marginRight: "1.2rem",
              minWidth: "3.4rem",
              width: "3.4rem",
              height: "3.4rem",
              content: hasImage ? "''" : undefined,
              backgroundImage: `url(${activeItemImage || simulationEmpty})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderRadius: "var(--border-radius-smallest)",
            }
          : undefined,

        "::after":
          label && !isSearchValue
            ? {
                content: `"${label}"`,
                display: "block",
                maxWidth: "100%",
                margin: 0,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }
            : undefined,

        input: {
          position: "absolute",
        },
      };
    },
    singleValue: () => ({
      maxWidth: "100%",
      margin: 0,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",

      "::first-letter": {
        textTransform: isSessionTable ? "uppercase" : undefined,
      },
    }),
    multiValue: () => ({
      display: "none",
    }),
    input: () => ({
      position: "absolute",
      top: "2.8rem",
      left: "1.2rem",
      right: "1.2rem",
      cursor: "pointer",
    }),
    option: (_, state) => {
      const { isSelected, isFocused, data } = state;
      // const isDark: boolean = !!forDarkBg || !!hideBorder;
      const isDefaultSelect: boolean = !hasImage && !hideBorder && isSelected;
      const color = (): string => {
        if (hideBorder) {
          if (isSelected) {
            return "var(--dark-blue)";
          }
        }

        if (isDefaultSelect) {
          return "var(--dark-blue)";
        }

        return "var(--black)";
      };

      return {
        minHeight: "3.5rem",
        padding: "0.7rem 3rem 0.7rem 1.2rem",
        transition: "0.3s all",
        fontWeight: isDefaultSelect ? 600 : 400,
        fontSize: "1.4rem",
        lineHeight: "1.9rem",
        color: color(),
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        background:
          (isSelected && !hideBorder) || isFocused
            ? "var(--extra-light-purple)"
            : "var(--white)",
        position: "relative",

        ":not(:last-child)": hideBorder
          ? {
              boxShadow: "inset 0 -0.1rem 0 var(--extra-light-gray)",
            }
          : undefined,

        "::before": {
          marginRight: "1.2rem",
          minWidth: "3.4rem",
          width: "3.4rem",
          height: "3.4rem",
          content: hasImage ? "''" : undefined,
          backgroundImage: `url(${data.image || simulationEmpty})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          borderRadius: "var(--border-radius-smallest)",
        },

        "::after": isSelected
          ? {
              backgroundImage: `url(${iconCheckBlue})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              content: "''",
              minWidth: "1.1rem",
              width: "1.1rem",
              height: "auto",
              display: "block",
              position: "absolute",
              top: "0",
              bottom: "0",
              right: "1.2rem",
            }
          : undefined,

        ":hover": !isSelected
          ? {
              backgroundColor: "var(--extra-light-purple)",
            }
          : undefined,
      };
    },
    noOptionsMessage: (style) => ({
      ...style,
      textAlign: "left",
    }),
    menuList: (style) => ({
      ...style,
      padding: "0",
      borderRadius: "var(--border-radius-small)",
    }),
    menu: (style) => ({
      ...style,
      borderRadius: "var(--border-radius-small)",
      boxShadow: "0px 16px 24px 0px rgba(40, 58, 151, 0.20)",
      minWidth: "12rem",
    }),
  };
};
