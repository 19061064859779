import { ChangeEvent, FC, KeyboardEvent, ReactElement } from "react";
import { IBaseInput } from "../../../models/base-input";
import { InputTemplate } from "../input-template/input-template";

export interface BaseInputNumberProps extends IBaseInput {
  min?: number;
  max?: number;
  step?: number;
}

export const BaseInputNumber: FC<BaseInputNumberProps> = ({
  value = "",
  name,
  placeholder,
  error = false,
  disabled,
  onChange,
  min,
  max,
  step,
  isSmall,
  forDarkBg,
  required,
  isContextBtnDisabled,
  readOnly,
  onRefresh,
  onDelete,
}): ReactElement => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;

    onChange({
      value: target.value,
      name: target.name,
    });
  };

  const onKeyPressHandler = (e: KeyboardEvent<HTMLInputElement>): void => {
    const currentKey: string = e.key;
    const exclusionKeys: string[] = ["Enter"];
    const isExclusionKeys: boolean = exclusionKeys.includes(currentKey);
    const isNumberKeys: boolean = /[\d.-]/.test(currentKey);

    if (!isNumberKeys && !isExclusionKeys) {
      e.preventDefault();
    }
  };

  return (
    <InputTemplate
      type="number"
      value={value}
      step={step}
      min={min}
      max={max}
      onChange={onChangeHandler}
      onKeyPress={onKeyPressHandler}
      name={name}
      disabled={disabled}
      error={error}
      isSmall={isSmall}
      forDarkBg={forDarkBg}
      required={required}
      placeholder={placeholder}
      isContextBtnDisabled={isContextBtnDisabled}
      readOnly={readOnly}
      onRefresh={onRefresh}
      onDelete={onDelete}
    />
  );
};
