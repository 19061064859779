import { FC, Fragment, ReactElement, Suspense } from "react";
import "../../styles/index.scss";
import "../../../shared/i18n/i18n";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import {
  ComplaintsPage,
  ContactsPage,
  FaqPage,
  LevelUpPage,
  Page404,
  PolicyPage,
  UiKitPage,
} from "../../../pages";
import {
  ChangePasswordPage,
  InfoPage,
  MyCompetencePage,
} from "../../../pages/profile";
import {
  ArchivePage as ArchiveHrAssessmentsPage,
  PlanedPage,
  SessionPage,
} from "../../../pages/sessions";
import { ActivePage, SimulationPage } from "../../../pages/simulation-control";
import {
  AddUserPage,
  AdminsPage,
  ArchivePage,
  HrsPage,
  PlayersPage,
  UserInfoPage,
} from "../../../pages/users-control";
import {
  ActiveAssessmentsPage,
  CreatedAssessmentsPage,
  FinishedAssessmentsPage,
} from "../../../pages/assessments-control";
import {
  AddContactPage,
  EditContactPage,
  EditContactsPage,
  EditFaqPage,
  EditInformationPage,
} from "../../../pages/other";
import useClient from "../../../shared/hooks/useClient";
import {
  FinesHistoryPage,
  RatingPage,
  RatingsPage,
  SessionsHistoryPage,
  TutorialHistoryPage,
} from "../../../pages/ratings";

import { PrivateRoute } from "../../../shared/components";

import SmsMailingListsPage from "../../../pages/mailing-lists/sms-mailing-lists-page/sms-mailing-lists-page";
import NewsletterForm from "../../../pages/mailing-lists/newsletters-form/newsletter-form";
import EmailMailingListsPage from "../../../pages/mailing-lists/email-mailing-lists-page/email-mailing-lists-page";
import SelectPlayersPage from "src/pages/other/select-players-page/ui/page/select-players-page";
import { SelectPlayersInviteAssessmentPage } from "../../../pages/select-players-invite-assessment";
import { useUnit } from "effector-react";
import {
  $specificRatingPermission,
  $specificSessionPermission,
} from "../../../entities/public/permissions/model";
import { FinesArchivePage, FinesPage } from "../../../pages/other/fines-page";
import { ExportHistoryPage } from "../../../pages/history-page/ratings/export-history/export-history-page";
import { SessionsExportHistoryPage } from "../../../pages/history-page/sessions/sessions-export-history-page";
import { ChangeHistoryPage } from "../../../pages/history-page/ratings/change-history/change-history-page";
import { UsersChangeHistoryPage } from "../../../pages/history-page/users/users-change-history-page";
import { EditCountriesPage } from "../../../pages/other/edit-countries-page";
import { NotificationsMailingListsPage } from "../../../pages/mailing-lists/notifications-mailing-lists-page/notifications-mailing-lists-page";
import { RoomConnectionProvider } from "../room-context/room-context";
import App from "../../App";
import { getSessionRoutes } from "../../../shared/helpers/getSessionRoutes";
import { RelativeModerationPage } from "../../../pages/moderation-page/relatives/relative-moderation-page";
import { StudentModerationPage } from "../../../pages/moderation-page/students/student-moderation-page";
import Keycloak from "keycloak-js";
import { EmployeeModerationPage } from "../../../pages/moderation-page/employees/employee-moderation-page";
import { StudentEmployeeModerationHistory } from "../../../pages/history-page/moderation";
import { RelativeModerationHistoryPage } from "../../../pages/history-page/moderation";
import { ComplaintsHistoryPage } from "../../../pages/ratings/complaints-history-page/complaints-history-page";

interface Props {
  keycloak: Keycloak;
}

type routeType = {
  path: string;
  element: JSX.Element;
  children: {
    path: string;
    element: JSX.Element;
  }[];
}[];

export const AppRoutes: FC<Props> = ({ keycloak }): ReactElement => {
  const isSessionAccess = useUnit($specificSessionPermission);
  const isRatingAccess = useUnit($specificRatingPermission);
  const { isHrClientId, isAdminClientId } = useClient();
  let routes: routeType;

  switch (true) {
    case isAdminClientId: {
      routes = adminRoutes(
        keycloak,
        isRatingAccess.has_permission,
        isSessionAccess.has_permission,
      );
      break;
    }
    case isHrClientId: {
      routes = hrRoutes(
        keycloak,
        isRatingAccess.has_permission,
        isSessionAccess.has_permission,
      );
      break;
    }
    default: {
      routes = playerRoutes(keycloak);
      break;
    }
  }

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

export const playerRoutes = (keycloak: Keycloak) => [
  {
    path: "/",
    element: (
      <RoomConnectionProvider>
        <App keycloak={keycloak}>
          <Outlet />
        </App>
      </RoomConnectionProvider>
    ),
    children: [
      /* Редирект на стартовую страницу приложения */
      {
        path: "/",
        element: <Navigate to="/my-competence" replace />,
      },
      /* Страницы раздела "profile" */
      {
        path: "profile",
        element: <Navigate to="/profile/info" replace />,
      },
      {
        path: "profile/info",
        element: <InfoPage />,
      },
      {
        path: "my-competence",
        element: (
          <PrivateRoute>
            <MyCompetencePage />
          </PrivateRoute>
        ),
      },
      {
        path: "profile/change-password",
        element: <ChangePasswordPage />,
      },
      {
        path: "my-competence/:id/sessions-history",
        element: (
          <PrivateRoute>
            <SessionsHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "level-up",
        element: (
          <PrivateRoute>
            <LevelUpPage />
          </PrivateRoute>
        ),
      },
      {
        path: "sessions/session/:id",
        element: (
          <PrivateRoute>
            <SessionPage />
          </PrivateRoute>
        ),
      },
      /* Информационные страницы */
      {
        path: "contacts",
        element: <ContactsPage />,
      },
      {
        path: "faq",
        element: <FaqPage />,
      },
      {
        path: "policy",
        element: <PolicyPage />,
      },
      /* Страница UI-Kit для сбора всех переиспользуемых компонентов */
      {
        path: "ui-kit",
        element: <UiKitPage />,
      },
      /* Страница 404 */
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

const hrRoutes = (
  keycloak: Keycloak.KeycloakInstance,
  isRatingAccess: boolean,
  isSessionAccess: boolean,
) => [
  {
    path: "/",
    element: (
      <RoomConnectionProvider>
        <App keycloak={keycloak}>
          <Outlet />
        </App>
      </RoomConnectionProvider>
    ),
    children: [
      {
        path: "/",
        element: (
          <Navigate
            to={isRatingAccess ? "/ratings" : "/sessions/archive"}
            replace
          />
        ),
      },
      /* Страницы раздела "profile" */
      {
        path: "profile",
        element: <Navigate to="/profile/info" replace />,
      },
      {
        path: "profile/info",
        element: <InfoPage />,
      },
      {
        path: "profile/change-password",
        element: <ChangePasswordPage />,
      },
      /* Страницы раздела "Модерация" */
      {
        path: "moderation",
        element: <Navigate to="/moderation/students" replace />,
      },
      {
        path: "moderation/relatives",
        element: (
          <PrivateRoute>
            <RelativeModerationPage />
          </PrivateRoute>
        ),
      },
      {
        path: "moderation/students",
        element: (
          <PrivateRoute>
            <StudentModerationPage />
          </PrivateRoute>
        ),
      },
      {
        path: "moderation/employees",
        element: (
          <PrivateRoute>
            <EmployeeModerationPage />
          </PrivateRoute>
        ),
      },
      /* Страницы раздела "ratings" */
      {
        path: "ratings",
        element: (
          <PrivateRoute>
            <RatingsPage />
          </PrivateRoute>
        ),
      },
      {
        path: "ratings/rating/:id",
        element: (
          <PrivateRoute>
            <RatingPage />
          </PrivateRoute>
        ),
      },
      {
        path: "ratings/rating/:id/sessions-history",
        element: (
          <PrivateRoute>
            <SessionsHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "ratings/rating/:id/tutorial-history",
        element: (
          <PrivateRoute>
            <TutorialHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "ratings/rating/:id/fines-history",
        element: (
          <PrivateRoute>
            <FinesHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "ratings/rating/:id/complaints-history",
        element: (
          <PrivateRoute>
            <ComplaintsHistoryPage />
          </PrivateRoute>
        ),
      },
      /* Страницы раздела "sessions" */
      {
        path: "sessions",
        element: (
          <Navigate
            to={isSessionAccess ? "/sessions/planned" : "/sessions/archive"}
            replace
          />
        ),
      },
      {
        path: "sessions/archive",
        element: (
          <PrivateRoute>
            <ArchiveHrAssessmentsPage />
          </PrivateRoute>
        ),
      },
      {
        path: "sessions/session/:id",
        element: (
          <PrivateRoute>
            <SessionPage />
          </PrivateRoute>
        ),
      },
      ...getSessionRoutes({
        isSessionAccess: isSessionAccess,
        path: "sessions/active",
        children: <ActiveAssessmentsPage />,
      }),
      ...getSessionRoutes({
        isSessionAccess: isSessionAccess,
        path: "sessions/planned",
        children: <PlanedPage />,
      }),
      {
        path: "sessions/session/:id",
        element: (
          <PrivateRoute>
            <SessionPage />
          </PrivateRoute>
        ),
      },
      {
        path: "sessions/session/:id/invite",
        element: (
          <Suspense fallback={<Fragment />}>
            <PrivateRoute>
              <SelectPlayersInviteAssessmentPage />
            </PrivateRoute>
          </Suspense>
        ),
      },
      {
        path: "sessions/session/:id/revoke-invite",
        element: (
          <Suspense fallback={<Fragment />}>
            <PrivateRoute>
              <SelectPlayersInviteAssessmentPage />
            </PrivateRoute>
          </Suspense>
        ),
      },
      /* Информационные страницы */
      {
        path: "contacts",
        element: <ContactsPage />,
      },
      {
        path: "policy",
        element: <PolicyPage />,
      },
      /* Страница UI-Kit для сбора всех переиспользуемых компонентов */
      {
        path: "ui-kit",
        element: <UiKitPage />,
      },
      /* Страница 404 */
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

const adminRoutes = (
  keycloak: Keycloak.KeycloakInstance,
  isRatingAccess: boolean,
  isSessionAccess: boolean,
) => [
  {
    path: "/",
    element: (
      <RoomConnectionProvider>
        <App keycloak={keycloak}>
          <Outlet />
        </App>
      </RoomConnectionProvider>
    ),
    children: [
      {
        path: "/",
        element: (
          <Navigate
            replace
            to={isRatingAccess ? "/ratings" : "/users-control/players"}
          />
        ),
      },
      /*Страницы раздела "История"*/
      {
        path: "history/ratings/export",
        element: (
          <PrivateRoute>
            <ExportHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "history/ratings/change",
        element: (
          <PrivateRoute>
            <ChangeHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "history/sessions/export",
        element: (
          <PrivateRoute>
            <SessionsExportHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "history/users/change",
        element: (
          <PrivateRoute>
            <UsersChangeHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "history/moderation/students",
        element: (
          <PrivateRoute>
            <StudentEmployeeModerationHistory />
          </PrivateRoute>
        ),
      },
      {
        path: "history/moderation/relatives",
        element: (
          <PrivateRoute>
            <RelativeModerationHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "history/moderation/employees",
        element: (
          <PrivateRoute>
            <StudentEmployeeModerationHistory />
          </PrivateRoute>
        ),
      },
      {
        path: "history/users",
        element: <Navigate to="/history/users/change" replace />,
      },
      {
        path: "history/moderation",
        element: <Navigate to="/history/moderation/students" replace />,
      },
      {
        path: "history",
        element: <Navigate to="/history/ratings/export" replace />,
      },
      {
        path: "history/ratings",
        element: <Navigate to="/history/ratings/export" replace />,
      },
      {
        path: "history/sessions",
        element: <Navigate to="/history/sessions/export" replace />,
      },
      /* Страницы раздела "ratings" */
      {
        path: "ratings",
        element: (
          <PrivateRoute>
            <RatingsPage />
          </PrivateRoute>
        ),
      },
      {
        path: "ratings/rating/:id",
        element: (
          <PrivateRoute>
            <RatingPage />
          </PrivateRoute>
        ),
      },
      {
        path: "ratings/rating/:id/sessions-history",
        element: (
          <PrivateRoute>
            <SessionsHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "ratings/rating/:id/tutorial-history",
        element: (
          <PrivateRoute>
            <TutorialHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "ratings/rating/:id/fines-history",
        element: (
          <PrivateRoute>
            <FinesHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "ratings/rating/:id/complaints-history",
        element: (
          <PrivateRoute>
            <ComplaintsHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "users-control",
        element: <Navigate to="/users-control/players" replace />,
      },
      {
        path: "users-control/players",
        element: <PlayersPage />,
      },
      {
        path: "users-control/hrs",
        element: <HrsPage />,
      },
      {
        path: "users-control/admins",
        element: <AdminsPage />,
      },
      {
        path: "users-control/archive",
        element: <ArchivePage />,
      },
      {
        path: "users-control/add-user",
        element: <AddUserPage />,
      },
      {
        path: "users-control/user/:id/info",
        element: <UserInfoPage />,
      },
      {
        path: "users-control/user/:id/sessions-history",
        element: <SessionsHistoryPage />,
      },
      {
        path: "users-control/user/:id/tutorial-history",
        element: (
          <PrivateRoute>
            <TutorialHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "users-control/user/:id/fines-history",
        element: (
          <PrivateRoute>
            <FinesHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "users-control/user/:id/complaints-history",
        element: (
          <PrivateRoute>
            <ComplaintsHistoryPage />
          </PrivateRoute>
        ),
      },
      /* Страницы раздела "profile" */
      {
        path: "profile",
        element: <Navigate to="/profile/info" replace />,
      },
      {
        path: "profile/info",
        element: <InfoPage />,
      },
      {
        path: "profile/change-password",
        element: <ChangePasswordPage />,
      },
      {
        path: "simulation-control",
        element: <Navigate to="/simulation-control/active" replace />,
      },
      {
        path: "simulation-control/active",
        element: <ActivePage />,
      },
      {
        path: "simulation-control/simulation/:id",
        element: <SimulationPage />,
      },
      /* Страница рассылки */
      {
        path: "mailing-lists",
        element: <Navigate to="/mailing-lists/notifications" replace />,
      },
      {
        path: "mailing-lists/notifications",
        element: <NotificationsMailingListsPage />,
      },
      {
        path: "mailing-lists/e-mail",
        element: <EmailMailingListsPage />,
      },
      {
        path: "mailing-lists/sms",
        element: <SmsMailingListsPage />,
      },
      {
        path: "mailing-lists/newsletter-form",
        element: <NewsletterForm />,
      },
      {
        path: "mailing-lists/select-recipients",
        element: <SelectPlayersPage />,
      },
      {
        path: "assessments-control",
        element: (
          <Navigate
            to={
              isSessionAccess
                ? "/assessments-control/created"
                : "/assessments-control/finished"
            }
            replace
          />
        ),
      },
      {
        path: "assessments-control/finished",
        element: <FinishedAssessmentsPage />,
      },
      ...getSessionRoutes({
        isSessionAccess: isSessionAccess,
        path: "/assessments-control/created",
        children: <CreatedAssessmentsPage />,
      }),
      ...getSessionRoutes({
        isSessionAccess: isSessionAccess,
        path: "/assessments-control/active",
        children: <ActiveAssessmentsPage />,
      }),
      // Было закоменченно до этого
      // {
      //   path: "composite-rights",
      //   element: <CompositeRightsPage />,
      // },
      {
        path: "complaints",
        element: <ComplaintsPage />,
      },
      {
        path: "other",
        element: <Navigate to="/other/edit-faq" replace />,
      },
      {
        path: "other/edit-faq",
        element: <EditFaqPage />,
      },
      {
        path: "other/edit-information",
        element: <EditInformationPage />,
      },
      {
        path: "other/edit-contacts",
        element: <EditContactsPage />,
      },
      {
        path: "other/edit-contacts/:id",
        element: <EditContactPage />,
      },
      {
        path: "other/add-contact",
        element: <AddContactPage />,
      },
      {
        path: "other/countries",
        element: <EditCountriesPage />,
      },
      {
        path: "other/fines",
        element: <FinesPage />,
      },
      {
        path: "other/fines-archive",
        element: <FinesArchivePage />,
      },
      {
        path: "policy",
        element: <PolicyPage />,
      },
      /* Страница UI-Kit для сбора всех переиспользуемых компонентов */
      {
        path: "ui-kit",
        element: <UiKitPage />,
      },
      /* Страница 404 */
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];
