import axios from "axios";
import {
  AdminGamesApi,
  AdminGamesCalculationsApi,
  AdminAssessmentApiFactory,
  AdminAssessmentApi,
  AssessmentApi,
  AssessmentMembersApi,
  AssessmentValidateApi,
  Configuration,
  HrGamesApi,
  PlayerGamesApi,
  PlayerTutorialHistoryApi,
  PostDeletedAssessmentMembersApi,
  PlayerGamesApiFactory,
} from "src/generated/game";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_GAME_URL,
});

apiInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.REACT_APP_GAME_URL;

    config.headers = {
      Accept: "application/json",
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("alabuga-token")}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);
const makeCalculationsApi = () => {
  return new AdminGamesCalculationsApi(new Configuration(), "", apiInstance);
};

const makeAssessmentsRequest = () => {
  return new AssessmentApi(new Configuration(), "", apiInstance);
};

const makeSimulationsRequest = () => {
  return new AdminGamesApi(new Configuration(), "", apiInstance);
};

const makeSimulationsPlayerRequest = () => {
  return new PlayerGamesApi(new Configuration(), "", apiInstance);
};

const makeHrGamesRequest = () => {
  return new HrGamesApi(new Configuration(), "", apiInstance);
};

const makeHrGamesExportRequest = () => {
  return new HrGamesApi(
    new Configuration({
      baseOptions: {
        responseType: "blob",
        headers: {
          "Content-Type": "application/zip",
        },
      },
    }),
    "",
    apiInstance,
  );
};

const makeValidateAssessmentRequest = () => {
  return new AssessmentValidateApi(new Configuration(), "", apiInstance);
};

const makeAssessmentMembersRequest = () => {
  return new AssessmentMembersApi(new Configuration(), "", apiInstance);
};

const makeTutorialHistoryRequest = () => {
  return new PlayerTutorialHistoryApi(new Configuration(), "", apiInstance);
};

const makeDeleteAssessmentMembersRequest = () => {
  return new PostDeletedAssessmentMembersApi(
    new Configuration(),
    "",
    apiInstance,
  );
};

const makeAdminAssessmentApiRequest = () => {
  return new AdminAssessmentApi(new Configuration(), "", apiInstance);
};

const makeAdminAssessmentApi = () => {
  return AdminAssessmentApiFactory(new Configuration(), "", apiInstance);
};

const makePlayerGamesApiFactory = () => {
  return PlayerGamesApiFactory(new Configuration(), "", apiInstance);
};

export const gameService = {
  apiInstance,
  makeCalculationsApi,
  makeAssessmentsRequest,
  makeSimulationsRequest,
  makeSimulationsPlayerRequest,
  makeHrGamesRequest,
  makeHrGamesExportRequest,
  makeValidateAssessmentRequest,
  makeAssessmentMembersRequest,
  makeTutorialHistoryRequest,
  makeDeleteAssessmentMembersRequest,
  makeAdminAssessmentApi,
  makeAdminAssessmentApiRequest,
  makePlayerGamesApiFactory,
};
