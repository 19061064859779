/* tslint:disable */
/* eslint-disable */
/**
 * Alabuga Game API
 * Alabuga Game Service<br><a href=\"../static/alb_svc_game_v1.json\">alb_svc_game_v1.json</a><p/>    Some commonly used field names:    <ul>    <li><b>assessment_id</b> - Assessment ID (сессия, ассессмент)</li>    <li><b>game_id</b> - Game ID (симуляция)</li>    <li><b>player_id</b> - Player ID (участник)</li>    <li>      <b>gptId</b> - Game Params Template ID (шаблон настроек симуляции)    </li>    <li><b>cId</b> - Calculation ID (формула)</li>    <li><b>aSt</b> - Assessment Status</li>    <li><b>gType</b> - Game Type Enum (режим)</li>    <li><b>aType</b> - Assessment Type Enum</li>    </ul>
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface AdmAssessmentDto
 */
export interface AdmAssessmentDto {
  /**
   *
   * @type {string}
   * @memberof AdmAssessmentDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdmAssessmentDto
   */
  gameId: string;
  /**
   *
   * @type {GameType}
   * @memberof AdmAssessmentDto
   */
  gameType: GameType;
  /**
   *
   * @type {AssessmentStatus}
   * @memberof AdmAssessmentDto
   */
  assessmentStatus: AssessmentStatus;
  /**
   *
   * @type {CreatedBy}
   * @memberof AdmAssessmentDto
   */
  createdBy: CreatedBy;
  /**
   *
   * @type {StartedBy}
   * @memberof AdmAssessmentDto
   */
  startedBy: StartedBy;
  /**
   *
   * @type {AssessmentType}
   * @memberof AdmAssessmentDto
   */
  assessmentType: AssessmentType;
  /**
   *
   * @type {number}
   * @memberof AdmAssessmentDto
   */
  maxPlayers: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof AdmAssessmentDto
   */
  maxPlayersByRole?: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: MaxTeams; }}
   * @memberof AdmAssessmentDto
   */
  maxTeamsByName?: { [key: string]: MaxTeams };
  /**
   *
   * @type {number}
   * @memberof AdmAssessmentDto
   */
  curPlayers: number;
  /**
   *
   * @type {object}
   * @memberof AdmAssessmentDto
   */
  playQueryParams?: object;
  /**
   *
   * @type {number}
   * @memberof AdmAssessmentDto
   */
  minRate?: number;
  /**
   *
   * @type {number}
   * @memberof AdmAssessmentDto
   */
  maxRate?: number;
  /**
   *
   * @type {PLvlUpCompetence}
   * @memberof AdmAssessmentDto
   */
  competencies?: PLvlUpCompetence;
  /**
   *
   * @type {Array<AdmAssessmentPlayerDto>}
   * @memberof AdmAssessmentDto
   */
  players?: Array<AdmAssessmentPlayerDto>;
  /**
   *
   * @type {string}
   * @memberof AdmAssessmentDto
   */
  createdAt?: string;
  /**
   *
   * @type {UserInfo}
   * @memberof AdmAssessmentDto
   */
  createdByUser?: UserInfo;
  /**
   *
   * @type {string}
   * @memberof AdmAssessmentDto
   */
  startedAt?: string;
  /**
   *
   * @type {UserInfo}
   * @memberof AdmAssessmentDto
   */
  startedByUser?: UserInfo;
  /**
   *
   * @type {string}
   * @memberof AdmAssessmentDto
   */
  finishedAt?: string;
  /**
   *
   * @type {string}
   * @memberof AdmAssessmentDto
   */
  code?: string;
  /**
   *
   * @type {LocStrDto}
   * @memberof AdmAssessmentDto
   */
  gameTitle?: LocStrDto;
  /**
   *
   * @type {string}
   * @memberof AdmAssessmentDto
   */
  title?: string;
  /**
   *
   * @type {object}
   * @memberof AdmAssessmentDto
   */
  params?: object;
  /**
   *
   * @type {boolean}
   * @memberof AdmAssessmentDto
   */
  closedSession?: boolean;
  /**
   *
   * @type {string}
   * @memberof AdmAssessmentDto
   */
  copy_part_title?: string;
  /**
   *
   * @type {string}
   * @memberof AdmAssessmentDto
   */
  parent_copied_id?: string;
}
/**
 *
 * @export
 * @interface AdmAssessmentFinishReqDto
 */
export interface AdmAssessmentFinishReqDto {
  /**
   *
   * @type {Array<string>}
   * @memberof AdmAssessmentFinishReqDto
   */
  ids: Array<string>;
}
/**
 *
 * @export
 * @interface AdmAssessmentPlayerDto
 */
export interface AdmAssessmentPlayerDto {
  /**
   *
   * @type {string}
   * @memberof AdmAssessmentPlayerDto
   */
  pid: string;
  /**
   *
   * @type {boolean}
   * @memberof AdmAssessmentPlayerDto
   */
  isDistributed?: boolean;
  /**
   *
   * @type {string}
   * @memberof AdmAssessmentPlayerDto
   */
  firstname?: string;
  /**
   *
   * @type {string}
   * @memberof AdmAssessmentPlayerDto
   */
  lastname?: string;
  /**
   *
   * @type {string}
   * @memberof AdmAssessmentPlayerDto
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof AdmAssessmentPlayerDto
   */
  teamName?: string;
  /**
   *
   * @type {number}
   * @memberof AdmAssessmentPlayerDto
   */
  teamNum?: number;
  /**
   *
   * @type {number}
   * @memberof AdmAssessmentPlayerDto
   */
  corpNum?: number;
  /**
   *
   * @type {number}
   * @memberof AdmAssessmentPlayerDto
   */
  areaNum?: number;
}
/**
 *
 * @export
 * @interface AdmAssessmentResDto
 */
export interface AdmAssessmentResDto {
  /**
   *
   * @type {AdmAssessmentDto}
   * @memberof AdmAssessmentResDto
   */
  assessment: AdmAssessmentDto;
}
/**
 *
 * @export
 * @interface AdmAssessmentsResDto
 */
export interface AdmAssessmentsResDto {
  /**
   *
   * @type {Pagination}
   * @memberof AdmAssessmentsResDto
   */
  pagination: Pagination;
  /**
   *
   * @type {Array<AdmAssessmentDto>}
   * @memberof AdmAssessmentsResDto
   */
  assessments: Array<AdmAssessmentDto>;
}
/**
 *
 * @export
 * @interface AdmCalcDto
 */
export interface AdmCalcDto {
  /**
   *
   * @type {string}
   * @memberof AdmCalcDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdmCalcDto
   */
  gameId: string;
  /**
   *
   * @type {GameType}
   * @memberof AdmCalcDto
   */
  gameType: GameType;
  /**
   *
   * @type {AssessmentType}
   * @memberof AdmCalcDto
   */
  assessmentType: AssessmentType;
  /**
   *
   * @type {CalcType}
   * @memberof AdmCalcDto
   */
  calcType: CalcType;
  /**
   *
   * @type {string}
   * @memberof AdmCalcDto
   */
  formula: string;
  /**
   *
   * @type {Competence}
   * @memberof AdmCalcDto
   */
  competence?: Competence;
}
/**
 *
 * @export
 * @interface AdmCalcResDto
 */
export interface AdmCalcResDto {
  /**
   *
   * @type {AdmCalcDto}
   * @memberof AdmCalcResDto
   */
  calc: AdmCalcDto;
}
/**
 *
 * @export
 * @interface AdmCalcsResDto
 */
export interface AdmCalcsResDto {
  /**
   *
   * @type {Array<AdmCalcDto>}
   * @memberof AdmCalcsResDto
   */
  calcs: Array<AdmCalcDto>;
}
/**
 *
 * @export
 * @interface AssessmentAddMembersReqDto
 */
export interface AssessmentAddMembersReqDto {
  /**
   *
   * @type {Array<AssessmentMemberReqDto>}
   * @memberof AssessmentAddMembersReqDto
   */
  members: Array<AssessmentMemberReqDto>;
}
/**
 *
 * @export
 * @interface AssessmentAddMembersResDto
 */
export interface AssessmentAddMembersResDto {
  /**
   *
   * @type {string}
   * @memberof AssessmentAddMembersResDto
   */
  assessment_id: string;
  /**
   *
   * @type {Array<AssessmentMemberResDto>}
   * @memberof AssessmentAddMembersResDto
   */
  members: Array<AssessmentMemberResDto>;
  /**
   *
   * @type {Pagination}
   * @memberof AssessmentAddMembersResDto
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface AssessmentCopyReqDto
 */
export interface AssessmentCopyReqDto {
  /**
   *
   * @type {number}
   * @memberof AssessmentCopyReqDto
   */
  num_copy: number;
}
/**
 *
 * @export
 * @interface AssessmentDto
 */
export interface AssessmentDto {
  /**
   *
   * @type {string}
   * @memberof AssessmentDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentDto
   */
  gameId: string;
  /**
   *
   * @type {GameType}
   * @memberof AssessmentDto
   */
  gameType: GameType;
  /**
   *
   * @type {AssessmentStatus}
   * @memberof AssessmentDto
   */
  assessmentStatus: AssessmentStatus;
  /**
   *
   * @type {CreatedBy}
   * @memberof AssessmentDto
   */
  createdBy: CreatedBy;
  /**
   *
   * @type {Array<AdmAssessmentPlayerDto>}
   * @memberof AssessmentDto
   */
  players: Array<AdmAssessmentPlayerDto>;
  /**
   *
   * @type {AssessmentType}
   * @memberof AssessmentDto
   */
  assessmentType: AssessmentType;
  /**
   *
   * @type {boolean}
   * @memberof AssessmentDto
   */
  closed_session?: boolean;
  /**
   *
   * @type {string}
   * @memberof AssessmentDto
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof AssessmentDto
   */
  maxPlayers: number;
  /**
   *
   * @type {object}
   * @memberof AssessmentDto
   */
  maxTeamsByName: object;
  /**
   *
   * @type {number}
   * @memberof AssessmentDto
   */
  curPlayers: number;
  /**
   *
   * @type {object}
   * @memberof AssessmentDto
   */
  playQueryParams?: object;
  /**
   *
   * @type {number}
   * @memberof AssessmentDto
   */
  minRate?: number;
  /**
   *
   * @type {number}
   * @memberof AssessmentDto
   */
  maxRate?: number;
  /**
   *
   * @type {PLvlUpCompetence}
   * @memberof AssessmentDto
   */
  competencies?: PLvlUpCompetence;
  /**
   *
   * @type {number}
   * @memberof AssessmentDto
   */
  maxAreas?: number;
  /**
   *
   * @type {boolean}
   * @memberof AssessmentDto
   */
  required_participation?: boolean;
  /**
   *
   * @type {number}
   * @memberof AssessmentDto
   */
  absence_timeout?: number;
  /**
   *
   * @type {string}
   * @memberof AssessmentDto
   */
  copy_part_title?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentDto
   */
  parent_copied_id?: string;
}
/**
 *
 * @export
 * @interface AssessmentMemberReqDto
 */
export interface AssessmentMemberReqDto {
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberReqDto
   */
  playerId: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberReqDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberReqDto
   */
  studyWorkPlace?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberReqDto
   */
  locality?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberReqDto
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberReqDto
   */
  workPhone?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberReqDto
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberReqDto
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberReqDto
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberReqDto
   */
  teamName?: string;
  /**
   *
   * @type {number}
   * @memberof AssessmentMemberReqDto
   */
  teamNum?: number;
  /**
   *
   * @type {number}
   * @memberof AssessmentMemberReqDto
   */
  areaNum?: number;
  /**
   *
   * @type {number}
   * @memberof AssessmentMemberReqDto
   */
  corpNum?: number;
  /**
   *
   * @type {boolean}
   * @memberof AssessmentMemberReqDto
   */
  isDistributed?: boolean;
}
/**
 *
 * @export
 * @interface AssessmentMemberResDto
 */
export interface AssessmentMemberResDto {
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberResDto
   */
  playerId: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberResDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberResDto
   */
  studyWorkPlace?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberResDto
   */
  locality?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberResDto
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberResDto
   */
  workPhone?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberResDto
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberResDto
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberResDto
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentMemberResDto
   */
  teamName?: string;
  /**
   *
   * @type {number}
   * @memberof AssessmentMemberResDto
   */
  teamNum?: number;
  /**
   *
   * @type {number}
   * @memberof AssessmentMemberResDto
   */
  areaNum?: number;
  /**
   *
   * @type {number}
   * @memberof AssessmentMemberResDto
   */
  corpNum?: number;
  /**
   *
   * @type {boolean}
   * @memberof AssessmentMemberResDto
   */
  isDistributed?: boolean;
}
/**
 *
 * @export
 * @interface AssessmentQuitDto
 */
export interface AssessmentQuitDto {
  /**
   *
   * @type {string}
   * @memberof AssessmentQuitDto
   */
  uid: string;
}
/**
 *
 * @export
 * @interface AssessmentResDto
 */
export interface AssessmentResDto {
  /**
   *
   * @type {AssessmentDto}
   * @memberof AssessmentResDto
   */
  assessment: AssessmentDto;
}
/**
 *
 * @export
 * @interface AssessmentResultPlayerDto
 */
export interface AssessmentResultPlayerDto {
  /**
   *
   * @type {string}
   * @memberof AssessmentResultPlayerDto
   */
  uid: string;
  /**
   *
   * @type {number}
   * @memberof AssessmentResultPlayerDto
   */
  position?: number;
  /**
   *
   * @type {object}
   * @memberof AssessmentResultPlayerDto
   */
  payload?: object;
  /**
   *
   * @type {object}
   * @memberof AssessmentResultPlayerDto
   */
  results?: object;
}
/**
 *
 * @export
 * @interface AssessmentResultsDto
 */
export interface AssessmentResultsDto {
  /**
   *
   * @type {Array<AssessmentResultPlayerDto>}
   * @memberof AssessmentResultsDto
   */
  players: Array<AssessmentResultPlayerDto>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum AssessmentStatus {
  Lobby = "lobby",
  InProgress = "inProgress",
  Finished = "finished",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum AssessmentType {
  Rating = "rating",
  NonRating = "nonRating",
}

/**
 *
 * @export
 * @interface AssessmentValidateResDto
 */
export interface AssessmentValidateResDto {
  /**
   *
   * @type {boolean}
   * @memberof AssessmentValidateResDto
   */
  is_valid: boolean;
}
/**
 *
 * @export
 * @interface AssessmentsResDto
 */
export interface AssessmentsResDto {
  /**
   *
   * @type {Array<AssessmentDto>}
   * @memberof AssessmentsResDto
   */
  assessments: Array<AssessmentDto>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CalcType {
  Dislike = "dislike",
  General = "general",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum Competence {
  Analytics = "analytics",
  AnalyticsTenacity = "analyticsTenacity",
  Command = "command",
  CommandTenacity = "commandTenacity",
  Communication = "communication",
  CommunicationTenacity = "communicationTenacity",
  Thinking3d = "thinking3d",
  Thinking3dTenacity = "thinking3dTenacity",
  Economics = "economics",
  EconomicsTenacity = "economicsTenacity",
  Jurisprudence = "jurisprudence",
  JurisprudenceTenacity = "jurisprudenceTenacity",
  Microelectronics = "microelectronics",
  MicroelectronicsTenacity = "microelectronicsTenacity",
}

/**
 *
 * @export
 * @interface CreateAdmCalcDto
 */
export interface CreateAdmCalcDto {
  /**
   *
   * @type {GameType}
   * @memberof CreateAdmCalcDto
   */
  gameType: GameType;
  /**
   *
   * @type {AssessmentType}
   * @memberof CreateAdmCalcDto
   */
  assessmentType: AssessmentType;
  /**
   *
   * @type {CalcType}
   * @memberof CreateAdmCalcDto
   */
  calcType: CalcType;
  /**
   *
   * @type {string}
   * @memberof CreateAdmCalcDto
   */
  formula: string;
}
/**
 *
 * @export
 * @interface CreateAssessmentReqDto
 */
export interface CreateAssessmentReqDto {
  /**
   *
   * @type {boolean}
   * @memberof CreateAssessmentReqDto
   */
  closed_session?: boolean;
  /**
   *
   * @type {GameType}
   * @memberof CreateAssessmentReqDto
   */
  gameType?: GameType;
  /**
   *
   * @type {AssessmentType}
   * @memberof CreateAssessmentReqDto
   */
  assessmentType?: AssessmentType;
  /**
   *
   * @type {string}
   * @memberof CreateAssessmentReqDto
   */
  title?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateAssessmentReqDto
   */
  desiredRoles?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CreateAssessmentReqDto
   */
  rating?: number;
  /**
   *
   * @type {PLvlUpCompetence}
   * @memberof CreateAssessmentReqDto
   */
  competencies?: PLvlUpCompetence;
  /**
   *
   * @type {string}
   * @memberof CreateAssessmentReqDto
   */
  calc_name?: string;
  /**
   *
   * @type {number}
   * @memberof CreateAssessmentReqDto
   */
  maxPlayers?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof CreateAssessmentReqDto
   */
  maxPlayersByRole?: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: MaxTeams; }}
   * @memberof CreateAssessmentReqDto
   */
  maxTeamsByName?: { [key: string]: MaxTeams };
  /**
   *
   * @type {number}
   * @memberof CreateAssessmentReqDto
   */
  maxAreas?: number;
  /**
   *
   * @type {object}
   * @memberof CreateAssessmentReqDto
   */
  params?: object;
  /**
   *
   * @type {boolean}
   * @memberof CreateAssessmentReqDto
   */
  validate_players?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateAssessmentReqDto
   */
  mandatory?: boolean;
  /**
   *
   * @type {Array<AssessmentMemberReqDto>}
   * @memberof CreateAssessmentReqDto
   */
  members?: Array<AssessmentMemberReqDto>;
  /**
   *
   * @type {number}
   * @memberof CreateAssessmentReqDto
   */
  lobby_presence_timer?: number;
  /**
   *
   * @type {number}
   * @memberof CreateAssessmentReqDto
   */
  lobby_confirm_timer?: number;
  /**
   *
   * @type {boolean}
   * @memberof CreateAssessmentReqDto
   */
  is_timeout?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateAssessmentReqDto
   */
  required_participation?: boolean;
}
/**
 *
 * @export
 * @interface CreateAssessmentResDto
 */
export interface CreateAssessmentResDto {
  /**
   *
   * @type {string}
   * @memberof CreateAssessmentResDto
   */
  gameId: string;
  /**
   *
   * @type {GameType}
   * @memberof CreateAssessmentResDto
   */
  gameType: GameType;
  /**
   *
   * @type {AssessmentType}
   * @memberof CreateAssessmentResDto
   */
  assessmentType: AssessmentType;
  /**
   *
   * @type {string}
   * @memberof CreateAssessmentResDto
   */
  assessmentId?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateAssessmentResDto
   */
  closed_session?: boolean;
  /**
   *
   * @type {object}
   * @memberof CreateAssessmentResDto
   */
  playQueryParams?: object;
  /**
   *
   * @type {string}
   * @memberof CreateAssessmentResDto
   */
  playUrl?: string;
}
/**
 *
 * @export
 * @interface CreateGameReqDto
 */
export interface CreateGameReqDto {
  /**
   *
   * @type {string}
   * @memberof CreateGameReqDto
   */
  apiKey?: string;
  /**
   *
   * @type {LocStrDto}
   * @memberof CreateGameReqDto
   */
  title?: LocStrDto;
  /**
   *
   * @type {LocStrDto}
   * @memberof CreateGameReqDto
   */
  desc?: LocStrDto;
  /**
   *
   * @type {{ [key: string]: LocStrDto; }}
   * @memberof CreateGameReqDto
   */
  gameRules?: { [key: string]: LocStrDto };
  /**
   *
   * @type {string}
   * @memberof CreateGameReqDto
   */
  metaUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreateGameReqDto
   */
  createUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreateGameReqDto
   */
  playUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreateGameReqDto
   */
  finishUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreateGameReqDto
   */
  tutorialPlayUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreateGameReqDto
   */
  ltdTutorialPlayUrl?: string;
  /**
   *
   * @type {object}
   * @memberof CreateGameReqDto
   */
  rawMetaJson?: object;
  /**
   *
   * @type {TenacityEnabled}
   * @memberof CreateGameReqDto
   */
  tenacityEnabled?: TenacityEnabled;
  /**
   *
   * @type {Thinking3dEnabled}
   * @memberof CreateGameReqDto
   */
  thinking3dEnabled?: Thinking3dEnabled;
  /**
   *
   * @type {EconomicsEnabled}
   * @memberof CreateGameReqDto
   */
  economicsEnabled?: EconomicsEnabled;
  /**
   *
   * @type {JurisprudenceEnabled}
   * @memberof CreateGameReqDto
   */
  jurisprudenceEnabled?: JurisprudenceEnabled;
  /**
   *
   * @type {EconomicsEnabled}
   * @memberof CreateGameReqDto
   */
  microelectronicsEnabled?: EconomicsEnabled;
  /**
   *
   * @type {boolean}
   * @memberof CreateGameReqDto
   */
  isVisibleForPlayers?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CreatedBy {
  Auto = "auto",
  User = "user",
}

/**
 *
 * @export
 * @interface Dislike
 */
export interface Dislike {
  /**
   *
   * @type {string}
   * @memberof Dislike
   */
  pid: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Dislike
   */
  whom: Array<string>;
}
/**
 *
 * @export
 * @interface Dislikes
 */
export interface Dislikes {
  /**
   *
   * @type {Array<Dislike>}
   * @memberof Dislikes
   */
  dislikes: Array<Dislike>;
}
/**
 *
 * @export
 * @interface EconomicsEnabled
 */
export interface EconomicsEnabled {
  /**
   *
   * @type {boolean}
   * @memberof EconomicsEnabled
   */
  tutorial?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EconomicsEnabled
   */
  single?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EconomicsEnabled
   */
  solo?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EconomicsEnabled
   */
  team?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EconomicsEnabled
   */
  corp?: boolean;
}
/**
 *
 * @export
 * @interface ErrDto
 */
export interface ErrDto {
  /**
   *
   * @type {number}
   * @memberof ErrDto
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ErrDto
   */
  msg?: string;
}
/**
 *
 * @export
 * @interface ErrResDto
 */
export interface ErrResDto {
  /**
   *
   * @type {ErrDto}
   * @memberof ErrResDto
   */
  err: ErrDto;
}
/**
 *
 * @export
 * @interface GameDto
 */
export interface GameDto {
  /**
   *
   * @type {string}
   * @memberof GameDto
   */
  id: string;
  /**
   *
   * @type {object}
   * @memberof GameDto
   */
  rawMetaJson: object;
  /**
   *
   * @type {string}
   * @memberof GameDto
   */
  apiKey?: string;
  /**
   *
   * @type {LocStrDto}
   * @memberof GameDto
   */
  title?: LocStrDto;
  /**
   *
   * @type {LocStrDto}
   * @memberof GameDto
   */
  desc?: LocStrDto;
  /**
   *
   * @type {{ [key: string]: LocStrDto; }}
   * @memberof GameDto
   */
  gameRules?: { [key: string]: LocStrDto };
  /**
   *
   * @type {string}
   * @memberof GameDto
   */
  metaUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GameDto
   */
  createUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GameDto
   */
  playUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GameDto
   */
  tutorialPlayUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GameDto
   */
  ltdTutorialPlayUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GameDto
   */
  finishUrl?: string;
  /**
   *
   * @type {Array<GameParamsTemplateDto>}
   * @memberof GameDto
   */
  paramsTemplates?: Array<GameParamsTemplateDto>;
  /**
   *
   * @type {TenacityEnabled}
   * @memberof GameDto
   */
  tenacityEnabled?: TenacityEnabled;
  /**
   *
   * @type {Thinking3dEnabled}
   * @memberof GameDto
   */
  thinking3dEnabled?: Thinking3dEnabled;
  /**
   *
   * @type {EconomicsEnabled}
   * @memberof GameDto
   */
  economicsEnabled?: EconomicsEnabled;
  /**
   *
   * @type {JurisprudenceEnabled}
   * @memberof GameDto
   */
  jurisprudenceEnabled?: JurisprudenceEnabled;
  /**
   *
   * @type {MicroelectronicsEnabled}
   * @memberof GameDto
   */
  microelectronicsEnabled?: MicroelectronicsEnabled;
  /**
   *
   * @type {boolean}
   * @memberof GameDto
   */
  isVisibleForPlayers?: boolean;
}
/**
 *
 * @export
 * @interface GameParamsTemplateDto
 */
export interface GameParamsTemplateDto {
  /**
   *
   * @type {AssessmentType}
   * @memberof GameParamsTemplateDto
   */
  assessmentType: AssessmentType;
  /**
   *
   * @type {object}
   * @memberof GameParamsTemplateDto
   */
  params: object;
  /**
   *
   * @type {GameType}
   * @memberof GameParamsTemplateDto
   */
  gameType?: GameType;
  /**
   *
   * @type {string}
   * @memberof GameParamsTemplateDto
   */
  id?: string;
  /**
   *
   * @type {LocStrDto}
   * @memberof GameParamsTemplateDto
   */
  title?: LocStrDto;
  /**
   *
   * @type {boolean}
   * @memberof GameParamsTemplateDto
   */
  validate_players?: boolean;
  /**
   *
   * @type {number}
   * @memberof GameParamsTemplateDto
   */
  minRate?: number;
  /**
   *
   * @type {number}
   * @memberof GameParamsTemplateDto
   */
  maxRate?: number;
  /**
   *
   * @type {number}
   * @memberof GameParamsTemplateDto
   */
  maxPlayers?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof GameParamsTemplateDto
   */
  maxPlayersByRole?: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: MaxTeams; }}
   * @memberof GameParamsTemplateDto
   */
  maxTeamsByName?: { [key: string]: MaxTeams };
  /**
   *
   * @type {number}
   * @memberof GameParamsTemplateDto
   */
  minAreas?: number;
  /**
   *
   * @type {number}
   * @memberof GameParamsTemplateDto
   */
  maxAreas?: number;
  /**
   *
   * @type {boolean}
   * @memberof GameParamsTemplateDto
   */
  is_default?: boolean;
}
/**
 *
 * @export
 * @interface GameParamsTemplatesDto
 */
export interface GameParamsTemplatesDto {
  /**
   *
   * @type {Array<GameParamsTemplateDto>}
   * @memberof GameParamsTemplatesDto
   */
  templates: Array<GameParamsTemplateDto>;
}
/**
 *
 * @export
 * @interface GameResDto
 */
export interface GameResDto {
  /**
   *
   * @type {GameDto}
   * @memberof GameResDto
   */
  game: GameDto;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum GameType {
  Tutorial = "tutorial",
  TutorialLtd = "tutorial_ltd",
  Single = "single",
  Solo = "solo",
  Team = "team",
  Corp = "corp",
}

/**
 *
 * @export
 * @interface GamesResDto
 */
export interface GamesResDto {
  /**
   *
   * @type {Array<GameDto>}
   * @memberof GamesResDto
   */
  games: Array<GameDto>;
}
/**
 *
 * @export
 * @interface JurisprudenceEnabled
 */
export interface JurisprudenceEnabled {
  /**
   *
   * @type {boolean}
   * @memberof JurisprudenceEnabled
   */
  tutorial?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JurisprudenceEnabled
   */
  single?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JurisprudenceEnabled
   */
  solo?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JurisprudenceEnabled
   */
  team?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JurisprudenceEnabled
   */
  corp?: boolean;
}
/**
 *
 * @export
 * @interface LevelUpResDto
 */
export interface LevelUpResDto {
  /**
   *
   * @type {string}
   * @memberof LevelUpResDto
   */
  gameId: string;
  /**
   *
   * @type {GameType}
   * @memberof LevelUpResDto
   */
  gameType: GameType;
  /**
   *
   * @type {string}
   * @memberof LevelUpResDto
   */
  assessmentId?: string;
  /**
   *
   * @type {string}
   * @memberof LevelUpResDto
   */
  playUrl?: string;
}
/**
 *
 * @export
 * @interface LocStrDto
 */
export interface LocStrDto {
  /**
   *
   * @type {string}
   * @memberof LocStrDto
   */
  en?: string;
  /**
   *
   * @type {string}
   * @memberof LocStrDto
   */
  ru?: string;
}
/**
 *
 * @export
 * @interface MaxTeams
 */
export interface MaxTeams {
  /**
   *
   * @type {number}
   * @memberof MaxTeams
   */
  maxTeams?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof MaxTeams
   */
  maxPlayersByRole?: { [key: string]: number };
}
/**
 *
 * @export
 * @interface MicroelectronicsEnabled
 */
export interface MicroelectronicsEnabled {
  /**
   *
   * @type {boolean}
   * @memberof MicroelectronicsEnabled
   */
  tutorial?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MicroelectronicsEnabled
   */
  single?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MicroelectronicsEnabled
   */
  solo?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MicroelectronicsEnabled
   */
  team?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MicroelectronicsEnabled
   */
  corp?: boolean;
}
/**
 *
 * @export
 * @interface PGameDto
 */
export interface PGameDto {
  /**
   *
   * @type {string}
   * @memberof PGameDto
   */
  id: string;
  /**
   *
   * @type {object}
   * @memberof PGameDto
   */
  rawMetaJson: object;
  /**
   *
   * @type {LocStrDto}
   * @memberof PGameDto
   */
  title?: LocStrDto;
  /**
   *
   * @type {LocStrDto}
   * @memberof PGameDto
   */
  desc?: LocStrDto;
  /**
   *
   * @type {{ [key: string]: LocStrDto; }}
   * @memberof PGameDto
   */
  gameRules?: { [key: string]: LocStrDto };
  /**
   *
   * @type {LocStrDto}
   * @memberof PGameDto
   */
  logoUrl?: LocStrDto;
  /**
   *
   * @type {string}
   * @memberof PGameDto
   */
  backColor?: string;
  /**
   *
   * @type {string}
   * @memberof PGameDto
   */
  createUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PGameDto
   */
  playUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PGameDto
   */
  tutorialPlayUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PGameDto
   */
  ltdTutorialPlayUrl?: string;
  /**
   *
   * @type {Array<{ [key: string]: PLvlUpInfo; }>}
   * @memberof PGameDto
   */
  lvlUps?: Array<{ [key: string]: PLvlUpInfo }>;
  /**
   *
   * @type {boolean}
   * @memberof PGameDto
   */
  isVisibleForPlayers?: boolean;
}
/**
 *
 * @export
 * @interface PGameResDto
 */
export interface PGameResDto {
  /**
   *
   * @type {PGameDto}
   * @memberof PGameResDto
   */
  game: PGameDto;
}
/**
 *
 * @export
 * @interface PGamesResDto
 */
export interface PGamesResDto {
  /**
   *
   * @type {Array<PGameDto>}
   * @memberof PGamesResDto
   */
  games: Array<PGameDto>;
}
/**
 *
 * @export
 * @interface PLvlUpCompetence
 */
export interface PLvlUpCompetence {
  /**
   *
   * @type {Array<Competence>}
   * @memberof PLvlUpCompetence
   */
  combined: Array<Competence>;
}
/**
 *
 * @export
 * @interface PLvlUpInfo
 */
export interface PLvlUpInfo {
  /**
   *
   * @type {GameType}
   * @memberof PLvlUpInfo
   */
  gType: GameType;
  /**
   *
   * @type {boolean}
   * @memberof PLvlUpInfo
   */
  avail: boolean;
  /**
   *
   * @type {Array<RateBounds>}
   * @memberof PLvlUpInfo
   */
  rateBounds: Array<RateBounds>;
  /**
   *
   * @type {Array<RateBounds>}
   * @memberof PLvlUpInfo
   */
  tenacityBounds: Array<RateBounds>;
  /**
   *
   * @type {Array<PLvlUpCompetence>}
   * @memberof PLvlUpInfo
   */
  competences: Array<PLvlUpCompetence>;
}
/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageNum: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  totalPages: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  totalItems: number;
}
/**
 *
 * @export
 * @interface ParamsCatDto
 */
export interface ParamsCatDto {
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  absence_timeout?: number;
  /**
   *
   * @type {boolean}
   * @memberof ParamsCatDto
   */
  bot_watch_enabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  city_base_quota_max?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  city_base_quota_min?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  household_cost_nursery?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  household_cost_shop?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  initial_money_nursery?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  initial_money_shop?: number;
  /**
   *
   * @type {boolean}
   * @memberof ParamsCatDto
   */
  randomize_colors?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof ParamsCatDto
   */
  turn_durations?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  coefficient_transition_demand_to_next_step?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  household_cost_shop_ceo?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  household_cost_nursery_ceo?: number;
  /**
   *
   * @type {boolean}
   * @memberof ParamsCatDto
   */
  with_random_last_turn?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParamsCatDto
   */
  with_bots?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParamsCatDto
   */
  with_credit?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParamsCatDto
   */
  with_hide_information?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParamsCatDto
   */
  with_ltd?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParamsCatDto
   */
  with_delegation_ltd?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParamsCatDto
   */
  with_realtime?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParamsCatDto
   */
  with_support_bot?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParamsCatDto
   */
  with_chance_bankruptcy?: boolean;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  chance_percent?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  big_car_buy_cost?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  big_car_rent_cost?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  variable_import_export?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  special_credit_rate?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  small_car_rent_cost?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  small_car_buy_cost?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  seasons_number?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  possible_credit_for_shop?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  possible_credit_for_nursery?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  initial_money_single_nursery_bot?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  initial_money_single_shop_bot?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  initial_money_team_in_city?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  investment_credit_rate?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  ltd_max_cat_price?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  ltd_max_num_of_cats?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  max_bank_guarantee?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  max_possible_fine?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  month_duration?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  initial_money_ceo?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  city_corp_max_quota?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  city_corp_min_quota?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  coefficient_demand_for_step_down?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  coefficient_demand_for_step_up?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  coefficient_price_for_step_down?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  coefficient_price_for_step_up?: number;
  /**
   *
   * @type {number}
   * @memberof ParamsCatDto
   */
  consumer_credit_rate?: number;
  /**
   *
   * @type {boolean}
   * @memberof ParamsCatDto
   */
  with_privileges_change?: boolean;
}
/**
 *
 * @export
 * @interface PlayerTutorialHistoryDto
 */
export interface PlayerTutorialHistoryDto {
  /**
   *
   * @type {string}
   * @memberof PlayerTutorialHistoryDto
   */
  player_id: string;
  /**
   *
   * @type {string}
   * @memberof PlayerTutorialHistoryDto
   */
  history_id: string;
  /**
   *
   * @type {number}
   * @memberof PlayerTutorialHistoryDto
   */
  chapter_number: number;
  /**
   *
   * @type {number}
   * @memberof PlayerTutorialHistoryDto
   */
  attempts: number;
  /**
   *
   * @type {SimulationType}
   * @memberof PlayerTutorialHistoryDto
   */
  simulation_type: SimulationType;
  /**
   *
   * @type {string}
   * @memberof PlayerTutorialHistoryDto
   */
  date_of_complete?: string;
}
/**
 *
 * @export
 * @interface PlayerTutorialHistoryResponse
 */
export interface PlayerTutorialHistoryResponse {
  /**
   *
   * @type {Array<PlayerTutorialHistoryDto>}
   * @memberof PlayerTutorialHistoryResponse
   */
  tutorials: Array<PlayerTutorialHistoryDto>;
  /**
   *
   * @type {Pagination}
   * @memberof PlayerTutorialHistoryResponse
   */
  pagination: Pagination;
}
/**
 *
 * @export
 * @interface RateBounds
 */
export interface RateBounds {
  /**
   *
   * @type {Competence}
   * @memberof RateBounds
   */
  competence: Competence;
  /**
   *
   * @type {boolean}
   * @memberof RateBounds
   */
  avail: boolean;
  /**
   *
   * @type {number}
   * @memberof RateBounds
   */
  min?: number;
  /**
   *
   * @type {number}
   * @memberof RateBounds
   */
  max?: number;
}
/**
 *
 * @export
 * @interface RateDiff
 */
export interface RateDiff {
  /**
   *
   * @type {Competence}
   * @memberof RateDiff
   */
  competence: Competence;
  /**
   *
   * @type {number}
   * @memberof RateDiff
   */
  diff?: number;
}
/**
 *
 * @export
 * @interface SimFinishReq
 */
export interface SimFinishReq {
  /**
   *
   * @type {string}
   * @memberof SimFinishReq
   */
  assessment_id: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum SimulationType {
  AlabugaStartTutorial = "ALABUGA_START_TUTORIAL",
  BusinessCatsTutorial = "BUSINESS_CATS_TUTORIAL",
  BusinessCatsTutorialLtd = "BUSINESS_CATS_TUTORIAL_LTD",
  NpvTutorial = "NPV_TUTORIAL",
  MicroelectronicsTutorial = "MICROELECTRONICS_TUTORIAL",
  TrucksTutorial = "TRUCKS_TUTORIAL",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum StartedBy {
  Auto = "auto",
  User = "user",
  Unknown = "unknown",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum TeamNames {
  Shop = "shop",
  Nursery = "nursery",
}

/**
 *
 * @export
 * @interface TenacityEnabled
 */
export interface TenacityEnabled {
  /**
   *
   * @type {boolean}
   * @memberof TenacityEnabled
   */
  single?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TenacityEnabled
   */
  solo?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TenacityEnabled
   */
  team?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TenacityEnabled
   */
  corp?: boolean;
}
/**
 *
 * @export
 * @interface TestFormula
 */
export interface TestFormula {
  /**
   *
   * @type {string}
   * @memberof TestFormula
   */
  formula: string;
  /**
   *
   * @type {GameType}
   * @memberof TestFormula
   */
  gType: GameType;
  /**
   *
   * @type {CalcType}
   * @memberof TestFormula
   */
  calcType: CalcType;
  /**
   *
   * @type {Competence}
   * @memberof TestFormula
   */
  competence?: Competence;
}
/**
 *
 * @export
 * @interface TestFormulas
 */
export interface TestFormulas {
  /**
   *
   * @type {Array<TestFormula>}
   * @memberof TestFormulas
   */
  formulas: Array<TestFormula>;
}
/**
 *
 * @export
 * @interface TestResult
 */
export interface TestResult {
  /**
   *
   * @type {GameType}
   * @memberof TestResult
   */
  gType: GameType;
  /**
   *
   * @type {CalcType}
   * @memberof TestResult
   */
  calcType: CalcType;
  /**
   *
   * @type {Array<TestResultReward>}
   * @memberof TestResult
   */
  rRewards?: Array<TestResultReward>;
  /**
   *
   * @type {Array<TestResultAvail>}
   * @memberof TestResult
   */
  rAvails?: Array<TestResultAvail>;
  /**
   *
   * @type {Array<TestResultRole>}
   * @memberof TestResult
   */
  rRoles?: Array<TestResultRole>;
  /**
   *
   * @type {Array<TestResultDislike>}
   * @memberof TestResult
   */
  rDislikes?: Array<TestResultDislike>;
  /**
   *
   * @type {Competence}
   * @memberof TestResult
   */
  competence?: Competence;
}
/**
 *
 * @export
 * @interface TestResultAvail
 */
export interface TestResultAvail {
  /**
   *
   * @type {object}
   * @memberof TestResultAvail
   */
  result: object;
  /**
   *
   * @type {string}
   * @memberof TestResultAvail
   */
  err?: string;
}
/**
 *
 * @export
 * @interface TestResultDislike
 */
export interface TestResultDislike {
  /**
   *
   * @type {string}
   * @memberof TestResultDislike
   */
  who: string;
  /**
   *
   * @type {string}
   * @memberof TestResultDislike
   */
  whom: string;
  /**
   *
   * @type {string}
   * @memberof TestResultDislike
   */
  team?: string;
  /**
   *
   * @type {Array<RateDiff>}
   * @memberof TestResultDislike
   */
  diffs?: Array<RateDiff>;
  /**
   *
   * @type {string}
   * @memberof TestResultDislike
   */
  err?: string;
}
/**
 *
 * @export
 * @interface TestResultReward
 */
export interface TestResultReward {
  /**
   *
   * @type {number}
   * @memberof TestResultReward
   */
  playersCount?: number;
  /**
   *
   * @type {number}
   * @memberof TestResultReward
   */
  teamsCount?: number;
  /**
   *
   * @type {number}
   * @memberof TestResultReward
   */
  place?: number;
  /**
   *
   * @type {number}
   * @memberof TestResultReward
   */
  diff?: number;
  /**
   *
   * @type {object}
   * @memberof TestResultReward
   */
  p?: object;
  /**
   *
   * @type {string}
   * @memberof TestResultReward
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof TestResultReward
   */
  team?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof TestResultReward
   */
  chapters?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof TestResultReward
   */
  chaptersTotal?: number;
  /**
   *
   * @type {string}
   * @memberof TestResultReward
   */
  err?: string;
}
/**
 *
 * @export
 * @interface TestResultRole
 */
export interface TestResultRole {
  /**
   *
   * @type {number}
   * @memberof TestResultRole
   */
  analytics: number;
  /**
   *
   * @type {number}
   * @memberof TestResultRole
   */
  command: number;
  /**
   *
   * @type {number}
   * @memberof TestResultRole
   */
  communication: number;
  /**
   *
   * @type {number}
   * @memberof TestResultRole
   */
  thinking3d: number;
  /**
   *
   * @type {number}
   * @memberof TestResultRole
   */
  economics: number;
  /**
   *
   * @type {number}
   * @memberof TestResultRole
   */
  jurisprudence: number;
  /**
   *
   * @type {number}
   * @memberof TestResultRole
   */
  microelectronics: number;
  /**
   *
   * @type {string}
   * @memberof TestResultRole
   */
  roles?: string;
  /**
   *
   * @type {string}
   * @memberof TestResultRole
   */
  teams?: string;
  /**
   *
   * @type {string}
   * @memberof TestResultRole
   */
  err?: string;
}
/**
 *
 * @export
 * @interface TestResults
 */
export interface TestResults {
  /**
   *
   * @type {Array<TestResult>}
   * @memberof TestResults
   */
  results: Array<TestResult>;
}
/**
 *
 * @export
 * @interface Thinking3dEnabled
 */
export interface Thinking3dEnabled {
  /**
   *
   * @type {boolean}
   * @memberof Thinking3dEnabled
   */
  tutorial?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Thinking3dEnabled
   */
  single?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Thinking3dEnabled
   */
  solo?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Thinking3dEnabled
   */
  team?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Thinking3dEnabled
   */
  corp?: boolean;
}
/**
 *
 * @export
 * @interface TutorialDto
 */
export interface TutorialDto {
  /**
   *
   * @type {Array<number>}
   * @memberof TutorialDto
   */
  chapters: Array<number>;
}
/**
 *
 * @export
 * @interface TutorialLtdDto
 */
export interface TutorialLtdDto {
  /**
   *
   * @type {Array<number>}
   * @memberof TutorialLtdDto
   */
  chapters_ltd: Array<number>;
}
/**
 *
 * @export
 * @interface UpdAdmCalcDto
 */
export interface UpdAdmCalcDto {
  /**
   *
   * @type {GameType}
   * @memberof UpdAdmCalcDto
   */
  gameType: GameType;
  /**
   *
   * @type {AssessmentType}
   * @memberof UpdAdmCalcDto
   */
  assessmentType: AssessmentType;
  /**
   *
   * @type {CalcType}
   * @memberof UpdAdmCalcDto
   */
  calcType: CalcType;
  /**
   *
   * @type {string}
   * @memberof UpdAdmCalcDto
   */
  formula: string;
  /**
   *
   * @type {Competence}
   * @memberof UpdAdmCalcDto
   */
  competence?: Competence;
}
/**
 *
 * @export
 * @interface UpdAdmCalcsDto
 */
export interface UpdAdmCalcsDto {
  /**
   *
   * @type {Array<UpdAdmCalcDto>}
   * @memberof UpdAdmCalcsDto
   */
  calcs: Array<UpdAdmCalcDto>;
}
/**
 *
 * @export
 * @interface UpdGameReqDto
 */
export interface UpdGameReqDto {
  /**
   *
   * @type {string}
   * @memberof UpdGameReqDto
   */
  apiKey?: string;
  /**
   *
   * @type {LocStrDto}
   * @memberof UpdGameReqDto
   */
  title?: LocStrDto;
  /**
   *
   * @type {LocStrDto}
   * @memberof UpdGameReqDto
   */
  desc?: LocStrDto;
  /**
   *
   * @type {{ [key: string]: LocStrDto; }}
   * @memberof UpdGameReqDto
   */
  gameRules?: { [key: string]: LocStrDto };
  /**
   *
   * @type {string}
   * @memberof UpdGameReqDto
   */
  metaUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UpdGameReqDto
   */
  createUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UpdGameReqDto
   */
  playUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UpdGameReqDto
   */
  finishUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UpdGameReqDto
   */
  tutorialPlayUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UpdGameReqDto
   */
  ltdTutorialPlayUrl?: string;
  /**
   *
   * @type {object}
   * @memberof UpdGameReqDto
   */
  rawMetaJson?: object;
  /**
   *
   * @type {TenacityEnabled}
   * @memberof UpdGameReqDto
   */
  tenacityEnabled?: TenacityEnabled;
  /**
   *
   * @type {Thinking3dEnabled}
   * @memberof UpdGameReqDto
   */
  thinking3dEnabled?: Thinking3dEnabled;
  /**
   *
   * @type {EconomicsEnabled}
   * @memberof UpdGameReqDto
   */
  economicsEnabled?: EconomicsEnabled;
  /**
   *
   * @type {JurisprudenceEnabled}
   * @memberof UpdGameReqDto
   */
  jurisprudenceEnabled?: JurisprudenceEnabled;
  /**
   *
   * @type {EconomicsEnabled}
   * @memberof UpdGameReqDto
   */
  microelectronicsEnabled?: EconomicsEnabled;
  /**
   *
   * @type {boolean}
   * @memberof UpdGameReqDto
   */
  isVisibleForPlayers?: boolean;
  /**
   *
   * @type {Array<GameParamsTemplateDto>}
   * @memberof UpdGameReqDto
   */
  paramsTemplates?: Array<GameParamsTemplateDto>;
}
/**
 *
 * @export
 * @interface UpdateAssessmentReqDto
 */
export interface UpdateAssessmentReqDto {
  /**
   *
   * @type {string}
   * @memberof UpdateAssessmentReqDto
   */
  id?: string;
  /**
   *
   * @type {AssessmentType}
   * @memberof UpdateAssessmentReqDto
   */
  assessmentType?: AssessmentType;
  /**
   *
   * @type {string}
   * @memberof UpdateAssessmentReqDto
   */
  title?: string;
  /**
   *
   * @type {object}
   * @memberof UpdateAssessmentReqDto
   */
  params?: object;
}
/**
 *
 * @export
 * @interface UpdateAssessmentResDto
 */
export interface UpdateAssessmentResDto {
  /**
   *
   * @type {UpdateAssessmentReqDto}
   * @memberof UpdateAssessmentResDto
   */
  assessment: UpdateAssessmentReqDto;
}
/**
 *
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  uid: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  lastname: string;
}

/**
 * AdminAssessmentApi - axios parameter creator
 * @export
 */
export const AdminAssessmentApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete an Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delAdmAssessment: async (
      assessmentId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists("delAdmAssessment", "assessmentId", assessmentId);
      const localVarPath = `/api/v1/admin/assessments/{assessment_id}`.replace(
        `{${"assessment_id"}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get an Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdmAssessment: async (
      assessmentId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists("getAdmAssessment", "assessmentId", assessmentId);
      const localVarPath = `/api/v1/admin/assessments/{assessment_id}`.replace(
        `{${"assessment_id"}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Assessments
     * @param {Array<string>} [gameTitleQuery] Game Title
     * @param {Array<string>} [titleQuery] Assessment Title
     * @param {string} [firstName] First Name
     * @param {string} [lastName] Last Name
     * @param {'en' | 'ru'} [localeQuery] Title locales
     * @param {Array<string>} [codeQuery] Code
     * @param {Array<'single' | 'solo' | 'team' | 'corp'>} [gameTypeQuery] Game Type, default all
     * @param {Array<'rating' | 'nonRating'>} [assessmentTypeQuery] Assessment Type, default all
     * @param {Array<'lobby' | 'inProgress' | 'finished'>} [assessmentStatusQuery] Assessment Status, default lobby
     * @param {number} [curPlayersFromQuery] Current Players From Count
     * @param {number} [curPlayersToQuery] Current Players To Count
     * @param {number} [maxPlayersFromQuery] Maximum Players From Count
     * @param {number} [maxPlayersToQuery] Maximum Players To Count
     * @param {string} [createdFromQuery] Created At From Timestamp, the date-time notation as defined by RFC 3339, section 5.6, for example, 2017-07-21T17:32:28Z
     * @param {string} [createdToQuery] Created At To Timestamp
     * @param {string} [startedFromQuery] Started At From Timestamp
     * @param {string} [startedToQuery] Started At To Timestamp
     * @param {string} [finishedFromQuery] Finished At From Timestamp
     * @param {string} [finishedToQuery] Finished At To Timestamp
     * @param {boolean} [closedSession] Closed Session
     * @param {string} [orderByQuery] sort assessments by fields gameTitle/titleQuery/templateTitle/code/ /assessmentType/assessmentStatus/curPlayers/maxPlayers/created /started/finished, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdmAssessments: async (
      gameTitleQuery?: Array<string>,
      titleQuery?: Array<string>,
      firstName?: string,
      lastName?: string,
      localeQuery?: "en" | "ru",
      codeQuery?: Array<string>,
      gameTypeQuery?: Array<"single" | "solo" | "team" | "corp">,
      assessmentTypeQuery?: Array<"rating" | "nonRating">,
      assessmentStatusQuery?: Array<"lobby" | "inProgress" | "finished">,
      curPlayersFromQuery?: number,
      curPlayersToQuery?: number,
      maxPlayersFromQuery?: number,
      maxPlayersToQuery?: number,
      createdFromQuery?: string,
      createdToQuery?: string,
      startedFromQuery?: string,
      startedToQuery?: string,
      finishedFromQuery?: string,
      finishedToQuery?: string,
      closedSession?: boolean,
      orderByQuery?: string,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/assessments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      if (gameTitleQuery) {
        localVarQueryParameter["gameTitleQuery"] = gameTitleQuery;
      }

      if (titleQuery) {
        localVarQueryParameter["titleQuery"] = titleQuery;
      }

      if (firstName !== undefined) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName !== undefined) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (localeQuery !== undefined) {
        localVarQueryParameter["localeQuery"] = localeQuery;
      }

      if (codeQuery) {
        localVarQueryParameter["codeQuery"] = codeQuery;
      }

      if (gameTypeQuery) {
        localVarQueryParameter["gameTypeQuery"] = gameTypeQuery;
      }

      if (assessmentTypeQuery) {
        localVarQueryParameter["assessmentTypeQuery"] = assessmentTypeQuery;
      }

      if (assessmentStatusQuery) {
        localVarQueryParameter["assessmentStatusQuery"] = assessmentStatusQuery;
      }

      if (curPlayersFromQuery !== undefined) {
        localVarQueryParameter["curPlayersFromQuery"] = curPlayersFromQuery;
      }

      if (curPlayersToQuery !== undefined) {
        localVarQueryParameter["curPlayersToQuery"] = curPlayersToQuery;
      }

      if (maxPlayersFromQuery !== undefined) {
        localVarQueryParameter["maxPlayersFromQuery"] = maxPlayersFromQuery;
      }

      if (maxPlayersToQuery !== undefined) {
        localVarQueryParameter["maxPlayersToQuery"] = maxPlayersToQuery;
      }

      if (createdFromQuery !== undefined) {
        localVarQueryParameter["createdFromQuery"] =
          (createdFromQuery as any) instanceof Date
            ? (createdFromQuery as any).toISOString()
            : createdFromQuery;
      }

      if (createdToQuery !== undefined) {
        localVarQueryParameter["createdToQuery"] =
          (createdToQuery as any) instanceof Date
            ? (createdToQuery as any).toISOString()
            : createdToQuery;
      }

      if (startedFromQuery !== undefined) {
        localVarQueryParameter["startedFromQuery"] =
          (startedFromQuery as any) instanceof Date
            ? (startedFromQuery as any).toISOString()
            : startedFromQuery;
      }

      if (startedToQuery !== undefined) {
        localVarQueryParameter["startedToQuery"] =
          (startedToQuery as any) instanceof Date
            ? (startedToQuery as any).toISOString()
            : startedToQuery;
      }

      if (finishedFromQuery !== undefined) {
        localVarQueryParameter["finishedFromQuery"] =
          (finishedFromQuery as any) instanceof Date
            ? (finishedFromQuery as any).toISOString()
            : finishedFromQuery;
      }

      if (finishedToQuery !== undefined) {
        localVarQueryParameter["finishedToQuery"] =
          (finishedToQuery as any) instanceof Date
            ? (finishedToQuery as any).toISOString()
            : finishedToQuery;
      }

      if (closedSession !== undefined) {
        localVarQueryParameter["closedSession"] = closedSession;
      }

      if (orderByQuery !== undefined) {
        localVarQueryParameter["orderByQuery"] = orderByQuery;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Force Finish Assessment
     * @param {AdmAssessmentFinishReqDto} admAssessmentFinishReqDtoBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAdmAssessmentFinish: async (
      admAssessmentFinishReqDtoBody: AdmAssessmentFinishReqDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'admAssessmentFinishReqDtoBody' is not null or undefined
      assertParamExists(
        "postAdmAssessmentFinish",
        "admAssessmentFinishReqDtoBody",
        admAssessmentFinishReqDtoBody,
      );
      const localVarPath = `/api/v1/admin/assessments/finish`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        admAssessmentFinishReqDtoBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminAssessmentApi - functional programming interface
 * @export
 */
export const AdminAssessmentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AdminAssessmentApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete an Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async delAdmAssessment(
      assessmentId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdmAssessmentResDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.delAdmAssessment(assessmentId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get an Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdmAssessment(
      assessmentId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdmAssessmentResDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAdmAssessment(assessmentId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Assessments
     * @param {Array<string>} [gameTitleQuery] Game Title
     * @param {Array<string>} [titleQuery] Assessment Title
     * @param {string} [firstName] First Name
     * @param {string} [lastName] Last Name
     * @param {'en' | 'ru'} [localeQuery] Title locales
     * @param {Array<string>} [codeQuery] Code
     * @param {Array<'single' | 'solo' | 'team' | 'corp'>} [gameTypeQuery] Game Type, default all
     * @param {Array<'rating' | 'nonRating'>} [assessmentTypeQuery] Assessment Type, default all
     * @param {Array<'lobby' | 'inProgress' | 'finished'>} [assessmentStatusQuery] Assessment Status, default lobby
     * @param {number} [curPlayersFromQuery] Current Players From Count
     * @param {number} [curPlayersToQuery] Current Players To Count
     * @param {number} [maxPlayersFromQuery] Maximum Players From Count
     * @param {number} [maxPlayersToQuery] Maximum Players To Count
     * @param {string} [createdFromQuery] Created At From Timestamp, the date-time notation as defined by RFC 3339, section 5.6, for example, 2017-07-21T17:32:28Z
     * @param {string} [createdToQuery] Created At To Timestamp
     * @param {string} [startedFromQuery] Started At From Timestamp
     * @param {string} [startedToQuery] Started At To Timestamp
     * @param {string} [finishedFromQuery] Finished At From Timestamp
     * @param {string} [finishedToQuery] Finished At To Timestamp
     * @param {boolean} [closedSession] Closed Session
     * @param {string} [orderByQuery] sort assessments by fields gameTitle/titleQuery/templateTitle/code/ /assessmentType/assessmentStatus/curPlayers/maxPlayers/created /started/finished, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdmAssessments(
      gameTitleQuery?: Array<string>,
      titleQuery?: Array<string>,
      firstName?: string,
      lastName?: string,
      localeQuery?: "en" | "ru",
      codeQuery?: Array<string>,
      gameTypeQuery?: Array<"single" | "solo" | "team" | "corp">,
      assessmentTypeQuery?: Array<"rating" | "nonRating">,
      assessmentStatusQuery?: Array<"lobby" | "inProgress" | "finished">,
      curPlayersFromQuery?: number,
      curPlayersToQuery?: number,
      maxPlayersFromQuery?: number,
      maxPlayersToQuery?: number,
      createdFromQuery?: string,
      createdToQuery?: string,
      startedFromQuery?: string,
      startedToQuery?: string,
      finishedFromQuery?: string,
      finishedToQuery?: string,
      closedSession?: boolean,
      orderByQuery?: string,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdmAssessmentsResDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAdmAssessments(
          gameTitleQuery,
          titleQuery,
          firstName,
          lastName,
          localeQuery,
          codeQuery,
          gameTypeQuery,
          assessmentTypeQuery,
          assessmentStatusQuery,
          curPlayersFromQuery,
          curPlayersToQuery,
          maxPlayersFromQuery,
          maxPlayersToQuery,
          createdFromQuery,
          createdToQuery,
          startedFromQuery,
          startedToQuery,
          finishedFromQuery,
          finishedToQuery,
          closedSession,
          orderByQuery,
          pageNum,
          pageSize,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Force Finish Assessment
     * @param {AdmAssessmentFinishReqDto} admAssessmentFinishReqDtoBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAdmAssessmentFinish(
      admAssessmentFinishReqDtoBody: AdmAssessmentFinishReqDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postAdmAssessmentFinish(
          admAssessmentFinishReqDtoBody,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminAssessmentApi - factory interface
 * @export
 */
export const AdminAssessmentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminAssessmentApiFp(configuration);
  return {
    /**
     *
     * @summary Delete an Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delAdmAssessment(
      assessmentId: string,
      options?: any,
    ): AxiosPromise<AdmAssessmentResDto> {
      return localVarFp
        .delAdmAssessment(assessmentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get an Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdmAssessment(
      assessmentId: string,
      options?: any,
    ): AxiosPromise<AdmAssessmentResDto> {
      return localVarFp
        .getAdmAssessment(assessmentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Assessments
     * @param {Array<string>} [gameTitleQuery] Game Title
     * @param {Array<string>} [titleQuery] Assessment Title
     * @param {string} [firstName] First Name
     * @param {string} [lastName] Last Name
     * @param {'en' | 'ru'} [localeQuery] Title locales
     * @param {Array<string>} [codeQuery] Code
     * @param {Array<'single' | 'solo' | 'team' | 'corp'>} [gameTypeQuery] Game Type, default all
     * @param {Array<'rating' | 'nonRating'>} [assessmentTypeQuery] Assessment Type, default all
     * @param {Array<'lobby' | 'inProgress' | 'finished'>} [assessmentStatusQuery] Assessment Status, default lobby
     * @param {number} [curPlayersFromQuery] Current Players From Count
     * @param {number} [curPlayersToQuery] Current Players To Count
     * @param {number} [maxPlayersFromQuery] Maximum Players From Count
     * @param {number} [maxPlayersToQuery] Maximum Players To Count
     * @param {string} [createdFromQuery] Created At From Timestamp, the date-time notation as defined by RFC 3339, section 5.6, for example, 2017-07-21T17:32:28Z
     * @param {string} [createdToQuery] Created At To Timestamp
     * @param {string} [startedFromQuery] Started At From Timestamp
     * @param {string} [startedToQuery] Started At To Timestamp
     * @param {string} [finishedFromQuery] Finished At From Timestamp
     * @param {string} [finishedToQuery] Finished At To Timestamp
     * @param {boolean} [closedSession] Closed Session
     * @param {string} [orderByQuery] sort assessments by fields gameTitle/titleQuery/templateTitle/code/ /assessmentType/assessmentStatus/curPlayers/maxPlayers/created /started/finished, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdmAssessments(
      gameTitleQuery?: Array<string>,
      titleQuery?: Array<string>,
      firstName?: string,
      lastName?: string,
      localeQuery?: "en" | "ru",
      codeQuery?: Array<string>,
      gameTypeQuery?: Array<"single" | "solo" | "team" | "corp">,
      assessmentTypeQuery?: Array<"rating" | "nonRating">,
      assessmentStatusQuery?: Array<"lobby" | "inProgress" | "finished">,
      curPlayersFromQuery?: number,
      curPlayersToQuery?: number,
      maxPlayersFromQuery?: number,
      maxPlayersToQuery?: number,
      createdFromQuery?: string,
      createdToQuery?: string,
      startedFromQuery?: string,
      startedToQuery?: string,
      finishedFromQuery?: string,
      finishedToQuery?: string,
      closedSession?: boolean,
      orderByQuery?: string,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<AdmAssessmentsResDto> {
      return localVarFp
        .getAdmAssessments(
          gameTitleQuery,
          titleQuery,
          firstName,
          lastName,
          localeQuery,
          codeQuery,
          gameTypeQuery,
          assessmentTypeQuery,
          assessmentStatusQuery,
          curPlayersFromQuery,
          curPlayersToQuery,
          maxPlayersFromQuery,
          maxPlayersToQuery,
          createdFromQuery,
          createdToQuery,
          startedFromQuery,
          startedToQuery,
          finishedFromQuery,
          finishedToQuery,
          closedSession,
          orderByQuery,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Force Finish Assessment
     * @param {AdmAssessmentFinishReqDto} admAssessmentFinishReqDtoBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAdmAssessmentFinish(
      admAssessmentFinishReqDtoBody: AdmAssessmentFinishReqDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postAdmAssessmentFinish(admAssessmentFinishReqDtoBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for delAdmAssessment operation in AdminAssessmentApi.
 * @export
 * @interface AdminAssessmentApiDelAdmAssessmentRequest
 */
export interface AdminAssessmentApiDelAdmAssessmentRequest {
  /**
   * Assessment UUID
   * @type {string}
   * @memberof AdminAssessmentApiDelAdmAssessment
   */
  readonly assessmentId: string;
}

/**
 * Request parameters for getAdmAssessment operation in AdminAssessmentApi.
 * @export
 * @interface AdminAssessmentApiGetAdmAssessmentRequest
 */
export interface AdminAssessmentApiGetAdmAssessmentRequest {
  /**
   * Assessment UUID
   * @type {string}
   * @memberof AdminAssessmentApiGetAdmAssessment
   */
  readonly assessmentId: string;
}

/**
 * Request parameters for getAdmAssessments operation in AdminAssessmentApi.
 * @export
 * @interface AdminAssessmentApiGetAdmAssessmentsRequest
 */
export interface AdminAssessmentApiGetAdmAssessmentsRequest {
  /**
   * Game Title
   * @type {Array<string>}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly gameTitleQuery?: Array<string>;

  /**
   * Assessment Title
   * @type {Array<string>}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly titleQuery?: Array<string>;

  /**
   * First Name
   * @type {string}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly firstName?: string;

  /**
   * Last Name
   * @type {string}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly lastName?: string;

  /**
   * Title locales
   * @type {'en' | 'ru'}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly localeQuery?: "en" | "ru";

  /**
   * Code
   * @type {Array<string>}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly codeQuery?: Array<string>;

  /**
   * Game Type, default all
   * @type {Array<'single' | 'solo' | 'team' | 'corp'>}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly gameTypeQuery?: Array<"single" | "solo" | "team" | "corp">;

  /**
   * Assessment Type, default all
   * @type {Array<'rating' | 'nonRating'>}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly assessmentTypeQuery?: Array<"rating" | "nonRating">;

  /**
   * Assessment Status, default lobby
   * @type {Array<'lobby' | 'inProgress' | 'finished'>}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly assessmentStatusQuery?: Array<"lobby" | "inProgress" | "finished">;

  /**
   * Current Players From Count
   * @type {number}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly curPlayersFromQuery?: number;

  /**
   * Current Players To Count
   * @type {number}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly curPlayersToQuery?: number;

  /**
   * Maximum Players From Count
   * @type {number}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly maxPlayersFromQuery?: number;

  /**
   * Maximum Players To Count
   * @type {number}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly maxPlayersToQuery?: number;

  /**
   * Created At From Timestamp, the date-time notation as defined by RFC 3339, section 5.6, for example, 2017-07-21T17:32:28Z
   * @type {string}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly createdFromQuery?: string;

  /**
   * Created At To Timestamp
   * @type {string}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly createdToQuery?: string;

  /**
   * Started At From Timestamp
   * @type {string}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly startedFromQuery?: string;

  /**
   * Started At To Timestamp
   * @type {string}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly startedToQuery?: string;

  /**
   * Finished At From Timestamp
   * @type {string}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly finishedFromQuery?: string;

  /**
   * Finished At To Timestamp
   * @type {string}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly finishedToQuery?: string;

  /**
   * Closed Session
   * @type {boolean}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly closedSession?: boolean;

  /**
   * sort assessments by fields gameTitle/titleQuery/templateTitle/code/ /assessmentType/assessmentStatus/curPlayers/maxPlayers/created /started/finished, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
   * @type {string}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly orderByQuery?: string;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof AdminAssessmentApiGetAdmAssessments
   */
  readonly pageSize?: number;
}

/**
 * Request parameters for postAdmAssessmentFinish operation in AdminAssessmentApi.
 * @export
 * @interface AdminAssessmentApiPostAdmAssessmentFinishRequest
 */
export interface AdminAssessmentApiPostAdmAssessmentFinishRequest {
  /**
   *
   * @type {AdmAssessmentFinishReqDto}
   * @memberof AdminAssessmentApiPostAdmAssessmentFinish
   */
  readonly admAssessmentFinishReqDtoBody: AdmAssessmentFinishReqDto;
}

/**
 * AdminAssessmentApi - object-oriented interface
 * @export
 * @class AdminAssessmentApi
 * @extends {BaseAPI}
 */
export class AdminAssessmentApi extends BaseAPI {
  /**
   *
   * @summary Delete an Assessment
   * @param {AdminAssessmentApiDelAdmAssessmentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminAssessmentApi
   */
  public delAdmAssessment(
    requestParameters: AdminAssessmentApiDelAdmAssessmentRequest,
    options?: any,
  ) {
    return AdminAssessmentApiFp(this.configuration)
      .delAdmAssessment(requestParameters.assessmentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get an Assessment
   * @param {AdminAssessmentApiGetAdmAssessmentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminAssessmentApi
   */
  public getAdmAssessment(
    requestParameters: AdminAssessmentApiGetAdmAssessmentRequest,
    options?: any,
  ) {
    return AdminAssessmentApiFp(this.configuration)
      .getAdmAssessment(requestParameters.assessmentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Assessments
   * @param {AdminAssessmentApiGetAdmAssessmentsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminAssessmentApi
   */
  public getAdmAssessments(
    requestParameters: AdminAssessmentApiGetAdmAssessmentsRequest = {},
    options?: any,
  ) {
    return AdminAssessmentApiFp(this.configuration)
      .getAdmAssessments(
        requestParameters.gameTitleQuery,
        requestParameters.titleQuery,
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.localeQuery,
        requestParameters.codeQuery,
        requestParameters.gameTypeQuery,
        requestParameters.assessmentTypeQuery,
        requestParameters.assessmentStatusQuery,
        requestParameters.curPlayersFromQuery,
        requestParameters.curPlayersToQuery,
        requestParameters.maxPlayersFromQuery,
        requestParameters.maxPlayersToQuery,
        requestParameters.createdFromQuery,
        requestParameters.createdToQuery,
        requestParameters.startedFromQuery,
        requestParameters.startedToQuery,
        requestParameters.finishedFromQuery,
        requestParameters.finishedToQuery,
        requestParameters.closedSession,
        requestParameters.orderByQuery,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Force Finish Assessment
   * @param {AdminAssessmentApiPostAdmAssessmentFinishRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminAssessmentApi
   */
  public postAdmAssessmentFinish(
    requestParameters: AdminAssessmentApiPostAdmAssessmentFinishRequest,
    options?: any,
  ) {
    return AdminAssessmentApiFp(this.configuration)
      .postAdmAssessmentFinish(
        requestParameters.admAssessmentFinishReqDtoBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminGamesApi - axios parameter creator
 * @export
 */
export const AdminGamesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete Game
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delGame: async (
      gameId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("delGame", "gameId", gameId);
      const localVarPath = `/api/v1/admin/games/{game_id}`.replace(
        `{${"game_id"}}`,
        encodeURIComponent(String(gameId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Game Info
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGame: async (
      gameId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("getGame", "gameId", gameId);
      const localVarPath = `/api/v1/admin/games/{game_id}`.replace(
        `{${"game_id"}}`,
        encodeURIComponent(String(gameId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get available Games List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGames: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/games`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Request and update Game Meta Info by metaUrl
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGameMeta: async (
      gameId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("postGameMeta", "gameId", gameId);
      const localVarPath = `/api/v1/admin/games/{game_id}/pullMeta`.replace(
        `{${"game_id"}}`,
        encodeURIComponent(String(gameId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create new Game
     * @param {CreateGameReqDto} createGameReqDtoBody Game Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGames: async (
      createGameReqDtoBody: CreateGameReqDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createGameReqDtoBody' is not null or undefined
      assertParamExists(
        "postGames",
        "createGameReqDtoBody",
        createGameReqDtoBody,
      );
      const localVarPath = `/api/v1/admin/games`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createGameReqDtoBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Game
     * @param {string} gameId Simulation Game ID
     * @param {UpdGameReqDto} updGameReqDtoBody Game Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putGame: async (
      gameId: string,
      updGameReqDtoBody: UpdGameReqDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("putGame", "gameId", gameId);
      // verify required parameter 'updGameReqDtoBody' is not null or undefined
      assertParamExists("putGame", "updGameReqDtoBody", updGameReqDtoBody);
      const localVarPath = `/api/v1/admin/games/{game_id}`.replace(
        `{${"game_id"}}`,
        encodeURIComponent(String(gameId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updGameReqDtoBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminGamesApi - functional programming interface
 * @export
 */
export const AdminGamesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AdminGamesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Game
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async delGame(
      gameId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.delGame(
        gameId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Game Info
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGame(
      gameId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGame(
        gameId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get available Games List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGames(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GamesResDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getGames(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Request and update Game Meta Info by metaUrl
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postGameMeta(
      gameId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postGameMeta(
        gameId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create new Game
     * @param {CreateGameReqDto} createGameReqDtoBody Game Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postGames(
      createGameReqDtoBody: CreateGameReqDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postGames(
        createGameReqDtoBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update Game
     * @param {string} gameId Simulation Game ID
     * @param {UpdGameReqDto} updGameReqDtoBody Game Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putGame(
      gameId: string,
      updGameReqDtoBody: UpdGameReqDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putGame(
        gameId,
        updGameReqDtoBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminGamesApi - factory interface
 * @export
 */
export const AdminGamesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminGamesApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Game
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delGame(gameId: string, options?: any): AxiosPromise<GameResDto> {
      return localVarFp
        .delGame(gameId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Game Info
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGame(gameId: string, options?: any): AxiosPromise<GameResDto> {
      return localVarFp
        .getGame(gameId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get available Games List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGames(options?: any): AxiosPromise<GamesResDto> {
      return localVarFp
        .getGames(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Request and update Game Meta Info by metaUrl
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGameMeta(gameId: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .postGameMeta(gameId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create new Game
     * @param {CreateGameReqDto} createGameReqDtoBody Game Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGames(
      createGameReqDtoBody: CreateGameReqDto,
      options?: any,
    ): AxiosPromise<GameResDto> {
      return localVarFp
        .postGames(createGameReqDtoBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Game
     * @param {string} gameId Simulation Game ID
     * @param {UpdGameReqDto} updGameReqDtoBody Game Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putGame(
      gameId: string,
      updGameReqDtoBody: UpdGameReqDto,
      options?: any,
    ): AxiosPromise<GameResDto> {
      return localVarFp
        .putGame(gameId, updGameReqDtoBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for delGame operation in AdminGamesApi.
 * @export
 * @interface AdminGamesApiDelGameRequest
 */
export interface AdminGamesApiDelGameRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof AdminGamesApiDelGame
   */
  readonly gameId: string;
}

/**
 * Request parameters for getGame operation in AdminGamesApi.
 * @export
 * @interface AdminGamesApiGetGameRequest
 */
export interface AdminGamesApiGetGameRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof AdminGamesApiGetGame
   */
  readonly gameId: string;
}

/**
 * Request parameters for postGameMeta operation in AdminGamesApi.
 * @export
 * @interface AdminGamesApiPostGameMetaRequest
 */
export interface AdminGamesApiPostGameMetaRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof AdminGamesApiPostGameMeta
   */
  readonly gameId: string;
}

/**
 * Request parameters for postGames operation in AdminGamesApi.
 * @export
 * @interface AdminGamesApiPostGamesRequest
 */
export interface AdminGamesApiPostGamesRequest {
  /**
   * Game Info
   * @type {CreateGameReqDto}
   * @memberof AdminGamesApiPostGames
   */
  readonly createGameReqDtoBody: CreateGameReqDto;
}

/**
 * Request parameters for putGame operation in AdminGamesApi.
 * @export
 * @interface AdminGamesApiPutGameRequest
 */
export interface AdminGamesApiPutGameRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof AdminGamesApiPutGame
   */
  readonly gameId: string;

  /**
   * Game Info
   * @type {UpdGameReqDto}
   * @memberof AdminGamesApiPutGame
   */
  readonly updGameReqDtoBody: UpdGameReqDto;
}

/**
 * AdminGamesApi - object-oriented interface
 * @export
 * @class AdminGamesApi
 * @extends {BaseAPI}
 */
export class AdminGamesApi extends BaseAPI {
  /**
   *
   * @summary Delete Game
   * @param {AdminGamesApiDelGameRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesApi
   */
  public delGame(
    requestParameters: AdminGamesApiDelGameRequest,
    options?: any,
  ) {
    return AdminGamesApiFp(this.configuration)
      .delGame(requestParameters.gameId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Game Info
   * @param {AdminGamesApiGetGameRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesApi
   */
  public getGame(
    requestParameters: AdminGamesApiGetGameRequest,
    options?: any,
  ) {
    return AdminGamesApiFp(this.configuration)
      .getGame(requestParameters.gameId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get available Games List
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesApi
   */
  public getGames(options?: any) {
    return AdminGamesApiFp(this.configuration)
      .getGames(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Request and update Game Meta Info by metaUrl
   * @param {AdminGamesApiPostGameMetaRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesApi
   */
  public postGameMeta(
    requestParameters: AdminGamesApiPostGameMetaRequest,
    options?: any,
  ) {
    return AdminGamesApiFp(this.configuration)
      .postGameMeta(requestParameters.gameId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create new Game
   * @param {AdminGamesApiPostGamesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesApi
   */
  public postGames(
    requestParameters: AdminGamesApiPostGamesRequest,
    options?: any,
  ) {
    return AdminGamesApiFp(this.configuration)
      .postGames(requestParameters.createGameReqDtoBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Game
   * @param {AdminGamesApiPutGameRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesApi
   */
  public putGame(
    requestParameters: AdminGamesApiPutGameRequest,
    options?: any,
  ) {
    return AdminGamesApiFp(this.configuration)
      .putGame(
        requestParameters.gameId,
        requestParameters.updGameReqDtoBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminGamesCalculationsApi - axios parameter creator
 * @export
 */
export const AdminGamesCalculationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete existed Game Calculation
     * @param {string} gameId Simulation Game ID
     * @param {string} calcId Calculation UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCalc: async (
      gameId: string,
      calcId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("deleteCalc", "gameId", gameId);
      // verify required parameter 'calcId' is not null or undefined
      assertParamExists("deleteCalc", "calcId", calcId);
      const localVarPath = `/api/v1/admin/games/{game_id}/calcs/{calc_id}`
        .replace(`{${"game_id"}}`, encodeURIComponent(String(gameId)))
        .replace(`{${"calc_id"}}`, encodeURIComponent(String(calcId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get existed Game Calculation
     * @param {string} gameId Simulation Game ID
     * @param {string} calcId Calculation UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCalc: async (
      gameId: string,
      calcId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("getCalc", "gameId", gameId);
      // verify required parameter 'calcId' is not null or undefined
      assertParamExists("getCalc", "calcId", calcId);
      const localVarPath = `/api/v1/admin/games/{game_id}/calcs/{calc_id}`
        .replace(`{${"game_id"}}`, encodeURIComponent(String(gameId)))
        .replace(`{${"calc_id"}}`, encodeURIComponent(String(calcId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Game calculations
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCalcs: async (
      gameId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("getCalcs", "gameId", gameId);
      const localVarPath = `/api/v1/admin/games/{game_id}/calcs`.replace(
        `{${"game_id"}}`,
        encodeURIComponent(String(gameId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Test calculation formulas
     * @param {string} gameId Simulation Game ID
     * @param {TestFormulas} testCalcBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTestCalc: async (
      gameId: string,
      testCalcBody: TestFormulas,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("postTestCalc", "gameId", gameId);
      // verify required parameter 'testCalcBody' is not null or undefined
      assertParamExists("postTestCalc", "testCalcBody", testCalcBody);
      const localVarPath =
        `/api/v1/admin/games/{game_id}/calcs/testFormula`.replace(
          `{${"game_id"}}`,
          encodeURIComponent(String(gameId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        testCalcBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update all the game calculations
     * @param {string} gameId Simulation Game ID
     * @param {UpdAdmCalcsDto} calcBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postUpdCalcs: async (
      gameId: string,
      calcBody: UpdAdmCalcsDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("postUpdCalcs", "gameId", gameId);
      // verify required parameter 'calcBody' is not null or undefined
      assertParamExists("postUpdCalcs", "calcBody", calcBody);
      const localVarPath =
        `/api/v1/admin/games/{game_id}/calcs/bulkUpdate`.replace(
          `{${"game_id"}}`,
          encodeURIComponent(String(gameId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        calcBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminGamesCalculationsApi - functional programming interface
 * @export
 */
export const AdminGamesCalculationsApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    AdminGamesCalculationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete existed Game Calculation
     * @param {string} gameId Simulation Game ID
     * @param {string} calcId Calculation UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCalc(
      gameId: string,
      calcId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdmCalcResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCalc(
        gameId,
        calcId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get existed Game Calculation
     * @param {string} gameId Simulation Game ID
     * @param {string} calcId Calculation UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCalc(
      gameId: string,
      calcId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdmCalcResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCalc(
        gameId,
        calcId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Game calculations
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCalcs(
      gameId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdmCalcsResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCalcs(
        gameId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Test calculation formulas
     * @param {string} gameId Simulation Game ID
     * @param {TestFormulas} testCalcBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTestCalc(
      gameId: string,
      testCalcBody: TestFormulas,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestResults>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postTestCalc(
        gameId,
        testCalcBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update all the game calculations
     * @param {string} gameId Simulation Game ID
     * @param {UpdAdmCalcsDto} calcBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postUpdCalcs(
      gameId: string,
      calcBody: UpdAdmCalcsDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdmCalcsResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postUpdCalcs(
        gameId,
        calcBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminGamesCalculationsApi - factory interface
 * @export
 */
export const AdminGamesCalculationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminGamesCalculationsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete existed Game Calculation
     * @param {string} gameId Simulation Game ID
     * @param {string} calcId Calculation UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCalc(
      gameId: string,
      calcId: string,
      options?: any,
    ): AxiosPromise<AdmCalcResDto> {
      return localVarFp
        .deleteCalc(gameId, calcId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get existed Game Calculation
     * @param {string} gameId Simulation Game ID
     * @param {string} calcId Calculation UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCalc(
      gameId: string,
      calcId: string,
      options?: any,
    ): AxiosPromise<AdmCalcResDto> {
      return localVarFp
        .getCalc(gameId, calcId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Game calculations
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCalcs(gameId: string, options?: any): AxiosPromise<AdmCalcsResDto> {
      return localVarFp
        .getCalcs(gameId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Test calculation formulas
     * @param {string} gameId Simulation Game ID
     * @param {TestFormulas} testCalcBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTestCalc(
      gameId: string,
      testCalcBody: TestFormulas,
      options?: any,
    ): AxiosPromise<TestResults> {
      return localVarFp
        .postTestCalc(gameId, testCalcBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update all the game calculations
     * @param {string} gameId Simulation Game ID
     * @param {UpdAdmCalcsDto} calcBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postUpdCalcs(
      gameId: string,
      calcBody: UpdAdmCalcsDto,
      options?: any,
    ): AxiosPromise<AdmCalcsResDto> {
      return localVarFp
        .postUpdCalcs(gameId, calcBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteCalc operation in AdminGamesCalculationsApi.
 * @export
 * @interface AdminGamesCalculationsApiDeleteCalcRequest
 */
export interface AdminGamesCalculationsApiDeleteCalcRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof AdminGamesCalculationsApiDeleteCalc
   */
  readonly gameId: string;

  /**
   * Calculation UUID
   * @type {string}
   * @memberof AdminGamesCalculationsApiDeleteCalc
   */
  readonly calcId: string;
}

/**
 * Request parameters for getCalc operation in AdminGamesCalculationsApi.
 * @export
 * @interface AdminGamesCalculationsApiGetCalcRequest
 */
export interface AdminGamesCalculationsApiGetCalcRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof AdminGamesCalculationsApiGetCalc
   */
  readonly gameId: string;

  /**
   * Calculation UUID
   * @type {string}
   * @memberof AdminGamesCalculationsApiGetCalc
   */
  readonly calcId: string;
}

/**
 * Request parameters for getCalcs operation in AdminGamesCalculationsApi.
 * @export
 * @interface AdminGamesCalculationsApiGetCalcsRequest
 */
export interface AdminGamesCalculationsApiGetCalcsRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof AdminGamesCalculationsApiGetCalcs
   */
  readonly gameId: string;
}

/**
 * Request parameters for postTestCalc operation in AdminGamesCalculationsApi.
 * @export
 * @interface AdminGamesCalculationsApiPostTestCalcRequest
 */
export interface AdminGamesCalculationsApiPostTestCalcRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof AdminGamesCalculationsApiPostTestCalc
   */
  readonly gameId: string;

  /**
   *
   * @type {TestFormulas}
   * @memberof AdminGamesCalculationsApiPostTestCalc
   */
  readonly testCalcBody: TestFormulas;
}

/**
 * Request parameters for postUpdCalcs operation in AdminGamesCalculationsApi.
 * @export
 * @interface AdminGamesCalculationsApiPostUpdCalcsRequest
 */
export interface AdminGamesCalculationsApiPostUpdCalcsRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof AdminGamesCalculationsApiPostUpdCalcs
   */
  readonly gameId: string;

  /**
   *
   * @type {UpdAdmCalcsDto}
   * @memberof AdminGamesCalculationsApiPostUpdCalcs
   */
  readonly calcBody: UpdAdmCalcsDto;
}

/**
 * AdminGamesCalculationsApi - object-oriented interface
 * @export
 * @class AdminGamesCalculationsApi
 * @extends {BaseAPI}
 */
export class AdminGamesCalculationsApi extends BaseAPI {
  /**
   *
   * @summary Delete existed Game Calculation
   * @param {AdminGamesCalculationsApiDeleteCalcRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesCalculationsApi
   */
  public deleteCalc(
    requestParameters: AdminGamesCalculationsApiDeleteCalcRequest,
    options?: any,
  ) {
    return AdminGamesCalculationsApiFp(this.configuration)
      .deleteCalc(requestParameters.gameId, requestParameters.calcId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get existed Game Calculation
   * @param {AdminGamesCalculationsApiGetCalcRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesCalculationsApi
   */
  public getCalc(
    requestParameters: AdminGamesCalculationsApiGetCalcRequest,
    options?: any,
  ) {
    return AdminGamesCalculationsApiFp(this.configuration)
      .getCalc(requestParameters.gameId, requestParameters.calcId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Game calculations
   * @param {AdminGamesCalculationsApiGetCalcsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesCalculationsApi
   */
  public getCalcs(
    requestParameters: AdminGamesCalculationsApiGetCalcsRequest,
    options?: any,
  ) {
    return AdminGamesCalculationsApiFp(this.configuration)
      .getCalcs(requestParameters.gameId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Test calculation formulas
   * @param {AdminGamesCalculationsApiPostTestCalcRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesCalculationsApi
   */
  public postTestCalc(
    requestParameters: AdminGamesCalculationsApiPostTestCalcRequest,
    options?: any,
  ) {
    return AdminGamesCalculationsApiFp(this.configuration)
      .postTestCalc(
        requestParameters.gameId,
        requestParameters.testCalcBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update all the game calculations
   * @param {AdminGamesCalculationsApiPostUpdCalcsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesCalculationsApi
   */
  public postUpdCalcs(
    requestParameters: AdminGamesCalculationsApiPostUpdCalcsRequest,
    options?: any,
  ) {
    return AdminGamesCalculationsApiFp(this.configuration)
      .postUpdCalcs(
        requestParameters.gameId,
        requestParameters.calcBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminGamesParamsTemplatesApi - axios parameter creator
 * @export
 */
export const AdminGamesParamsTemplatesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete existed Game Params Template
     * @param {string} gameId Simulation Game ID
     * @param {string} templateId Template UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delTemplate: async (
      gameId: string,
      templateId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("delTemplate", "gameId", gameId);
      // verify required parameter 'templateId' is not null or undefined
      assertParamExists("delTemplate", "templateId", templateId);
      const localVarPath =
        `/api/v1/admin/games/{game_id}/templates/{template_id}`
          .replace(`{${"game_id"}}`, encodeURIComponent(String(gameId)))
          .replace(
            `{${"template_id"}}`,
            encodeURIComponent(String(templateId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get existed Game Params Template
     * @param {string} gameId Simulation Game ID
     * @param {string} templateId Template UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplate: async (
      gameId: string,
      templateId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("getTemplate", "gameId", gameId);
      // verify required parameter 'templateId' is not null or undefined
      assertParamExists("getTemplate", "templateId", templateId);
      const localVarPath =
        `/api/v1/admin/games/{game_id}/templates/{template_id}`
          .replace(`{${"game_id"}}`, encodeURIComponent(String(gameId)))
          .replace(
            `{${"template_id"}}`,
            encodeURIComponent(String(templateId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Game params templates
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplates: async (
      gameId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("getTemplates", "gameId", gameId);
      const localVarPath = `/api/v1/admin/games/{game_id}/templates`.replace(
        `{${"game_id"}}`,
        encodeURIComponent(String(gameId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add new game params template
     * @param {string} gameId Simulation Game ID
     * @param {GameParamsTemplateDto} templateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTemplate: async (
      gameId: string,
      templateBody: GameParamsTemplateDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("postTemplate", "gameId", gameId);
      // verify required parameter 'templateBody' is not null or undefined
      assertParamExists("postTemplate", "templateBody", templateBody);
      const localVarPath = `/api/v1/admin/games/{game_id}/templates`.replace(
        `{${"game_id"}}`,
        encodeURIComponent(String(gameId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        templateBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update existed Game Params Template
     * @param {string} gameId Simulation Game ID
     * @param {string} templateId Template UUID
     * @param {GameParamsTemplateDto} templateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTemplate: async (
      gameId: string,
      templateId: string,
      templateBody: GameParamsTemplateDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("putTemplate", "gameId", gameId);
      // verify required parameter 'templateId' is not null or undefined
      assertParamExists("putTemplate", "templateId", templateId);
      // verify required parameter 'templateBody' is not null or undefined
      assertParamExists("putTemplate", "templateBody", templateBody);
      const localVarPath =
        `/api/v1/admin/games/{game_id}/templates/{template_id}`
          .replace(`{${"game_id"}}`, encodeURIComponent(String(gameId)))
          .replace(
            `{${"template_id"}}`,
            encodeURIComponent(String(templateId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        templateBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminGamesParamsTemplatesApi - functional programming interface
 * @export
 */
export const AdminGamesParamsTemplatesApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    AdminGamesParamsTemplatesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete existed Game Params Template
     * @param {string} gameId Simulation Game ID
     * @param {string} templateId Template UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async delTemplate(
      gameId: string,
      templateId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GameParamsTemplateDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.delTemplate(
        gameId,
        templateId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get existed Game Params Template
     * @param {string} gameId Simulation Game ID
     * @param {string} templateId Template UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTemplate(
      gameId: string,
      templateId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GameParamsTemplateDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplate(
        gameId,
        templateId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Game params templates
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTemplates(
      gameId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GameParamsTemplatesDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplates(
        gameId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Add new game params template
     * @param {string} gameId Simulation Game ID
     * @param {GameParamsTemplateDto} templateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTemplate(
      gameId: string,
      templateBody: GameParamsTemplateDto,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GameParamsTemplateDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postTemplate(
        gameId,
        templateBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update existed Game Params Template
     * @param {string} gameId Simulation Game ID
     * @param {string} templateId Template UUID
     * @param {GameParamsTemplateDto} templateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putTemplate(
      gameId: string,
      templateId: string,
      templateBody: GameParamsTemplateDto,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GameParamsTemplateDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putTemplate(
        gameId,
        templateId,
        templateBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminGamesParamsTemplatesApi - factory interface
 * @export
 */
export const AdminGamesParamsTemplatesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminGamesParamsTemplatesApiFp(configuration);
  return {
    /**
     *
     * @summary Delete existed Game Params Template
     * @param {string} gameId Simulation Game ID
     * @param {string} templateId Template UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delTemplate(
      gameId: string,
      templateId: string,
      options?: any,
    ): AxiosPromise<GameParamsTemplateDto> {
      return localVarFp
        .delTemplate(gameId, templateId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get existed Game Params Template
     * @param {string} gameId Simulation Game ID
     * @param {string} templateId Template UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplate(
      gameId: string,
      templateId: string,
      options?: any,
    ): AxiosPromise<GameParamsTemplateDto> {
      return localVarFp
        .getTemplate(gameId, templateId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Game params templates
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplates(
      gameId: string,
      options?: any,
    ): AxiosPromise<GameParamsTemplatesDto> {
      return localVarFp
        .getTemplates(gameId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add new game params template
     * @param {string} gameId Simulation Game ID
     * @param {GameParamsTemplateDto} templateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTemplate(
      gameId: string,
      templateBody: GameParamsTemplateDto,
      options?: any,
    ): AxiosPromise<GameParamsTemplateDto> {
      return localVarFp
        .postTemplate(gameId, templateBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update existed Game Params Template
     * @param {string} gameId Simulation Game ID
     * @param {string} templateId Template UUID
     * @param {GameParamsTemplateDto} templateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTemplate(
      gameId: string,
      templateId: string,
      templateBody: GameParamsTemplateDto,
      options?: any,
    ): AxiosPromise<GameParamsTemplateDto> {
      return localVarFp
        .putTemplate(gameId, templateId, templateBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for delTemplate operation in AdminGamesParamsTemplatesApi.
 * @export
 * @interface AdminGamesParamsTemplatesApiDelTemplateRequest
 */
export interface AdminGamesParamsTemplatesApiDelTemplateRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof AdminGamesParamsTemplatesApiDelTemplate
   */
  readonly gameId: string;

  /**
   * Template UUID
   * @type {string}
   * @memberof AdminGamesParamsTemplatesApiDelTemplate
   */
  readonly templateId: string;
}

/**
 * Request parameters for getTemplate operation in AdminGamesParamsTemplatesApi.
 * @export
 * @interface AdminGamesParamsTemplatesApiGetTemplateRequest
 */
export interface AdminGamesParamsTemplatesApiGetTemplateRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof AdminGamesParamsTemplatesApiGetTemplate
   */
  readonly gameId: string;

  /**
   * Template UUID
   * @type {string}
   * @memberof AdminGamesParamsTemplatesApiGetTemplate
   */
  readonly templateId: string;
}

/**
 * Request parameters for getTemplates operation in AdminGamesParamsTemplatesApi.
 * @export
 * @interface AdminGamesParamsTemplatesApiGetTemplatesRequest
 */
export interface AdminGamesParamsTemplatesApiGetTemplatesRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof AdminGamesParamsTemplatesApiGetTemplates
   */
  readonly gameId: string;
}

/**
 * Request parameters for postTemplate operation in AdminGamesParamsTemplatesApi.
 * @export
 * @interface AdminGamesParamsTemplatesApiPostTemplateRequest
 */
export interface AdminGamesParamsTemplatesApiPostTemplateRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof AdminGamesParamsTemplatesApiPostTemplate
   */
  readonly gameId: string;

  /**
   *
   * @type {GameParamsTemplateDto}
   * @memberof AdminGamesParamsTemplatesApiPostTemplate
   */
  readonly templateBody: GameParamsTemplateDto;
}

/**
 * Request parameters for putTemplate operation in AdminGamesParamsTemplatesApi.
 * @export
 * @interface AdminGamesParamsTemplatesApiPutTemplateRequest
 */
export interface AdminGamesParamsTemplatesApiPutTemplateRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof AdminGamesParamsTemplatesApiPutTemplate
   */
  readonly gameId: string;

  /**
   * Template UUID
   * @type {string}
   * @memberof AdminGamesParamsTemplatesApiPutTemplate
   */
  readonly templateId: string;

  /**
   *
   * @type {GameParamsTemplateDto}
   * @memberof AdminGamesParamsTemplatesApiPutTemplate
   */
  readonly templateBody: GameParamsTemplateDto;
}

/**
 * AdminGamesParamsTemplatesApi - object-oriented interface
 * @export
 * @class AdminGamesParamsTemplatesApi
 * @extends {BaseAPI}
 */
export class AdminGamesParamsTemplatesApi extends BaseAPI {
  /**
   *
   * @summary Delete existed Game Params Template
   * @param {AdminGamesParamsTemplatesApiDelTemplateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesParamsTemplatesApi
   */
  public delTemplate(
    requestParameters: AdminGamesParamsTemplatesApiDelTemplateRequest,
    options?: any,
  ) {
    return AdminGamesParamsTemplatesApiFp(this.configuration)
      .delTemplate(
        requestParameters.gameId,
        requestParameters.templateId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get existed Game Params Template
   * @param {AdminGamesParamsTemplatesApiGetTemplateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesParamsTemplatesApi
   */
  public getTemplate(
    requestParameters: AdminGamesParamsTemplatesApiGetTemplateRequest,
    options?: any,
  ) {
    return AdminGamesParamsTemplatesApiFp(this.configuration)
      .getTemplate(
        requestParameters.gameId,
        requestParameters.templateId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Game params templates
   * @param {AdminGamesParamsTemplatesApiGetTemplatesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesParamsTemplatesApi
   */
  public getTemplates(
    requestParameters: AdminGamesParamsTemplatesApiGetTemplatesRequest,
    options?: any,
  ) {
    return AdminGamesParamsTemplatesApiFp(this.configuration)
      .getTemplates(requestParameters.gameId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add new game params template
   * @param {AdminGamesParamsTemplatesApiPostTemplateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesParamsTemplatesApi
   */
  public postTemplate(
    requestParameters: AdminGamesParamsTemplatesApiPostTemplateRequest,
    options?: any,
  ) {
    return AdminGamesParamsTemplatesApiFp(this.configuration)
      .postTemplate(
        requestParameters.gameId,
        requestParameters.templateBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update existed Game Params Template
   * @param {AdminGamesParamsTemplatesApiPutTemplateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminGamesParamsTemplatesApi
   */
  public putTemplate(
    requestParameters: AdminGamesParamsTemplatesApiPutTemplateRequest,
    options?: any,
  ) {
    return AdminGamesParamsTemplatesApiFp(this.configuration)
      .putTemplate(
        requestParameters.gameId,
        requestParameters.templateId,
        requestParameters.templateBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AssessmentApi - axios parameter creator
 * @export
 */
export const AssessmentApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete an Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delAssessment: async (
      assessmentId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists("delAssessment", "assessmentId", assessmentId);
      const localVarPath = `/api/v1/assessment/{assessment_id}`.replace(
        `{${"assessment_id"}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get an Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssessment: async (
      assessmentId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists("getAssessment", "assessmentId", assessmentId);
      const localVarPath = `/api/v1/assessment/{assessment_id}`.replace(
        `{${"assessment_id"}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Assessments
     * @param {Array<'lobby' | 'inProgress' | 'finished'>} [assessmentStatusQuery] Assessment Status, default lobby
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssessments: async (
      assessmentStatusQuery?: Array<"lobby" | "inProgress" | "finished">,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/assessment`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      if (assessmentStatusQuery) {
        localVarQueryParameter["assessmentStatusQuery"] = assessmentStatusQuery;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add Assessment Results
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentResultsDto} results Assessment results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAssessmentAdd: async (
      assessmentId: string,
      results: AssessmentResultsDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists("postAssessmentAdd", "assessmentId", assessmentId);
      // verify required parameter 'results' is not null or undefined
      assertParamExists("postAssessmentAdd", "results", results);
      const localVarPath = `/api/v1/assessment/{assessment_id}/add`.replace(
        `{${"assessment_id"}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        results,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Dislike players
     * @param {string} assessmentId Assessment UUID
     * @param {Dislikes} dislikeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDislike: async (
      assessmentId: string,
      dislikeBody: Dislikes,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists("postDislike", "assessmentId", assessmentId);
      // verify required parameter 'dislikeBody' is not null or undefined
      assertParamExists("postDislike", "dislikeBody", dislikeBody);
      const localVarPath = `/api/v1/assessment/{assessment_id}/dislike`.replace(
        `{${"assessment_id"}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dislikeBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Quit player from an Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentQuitDto} player Assessment results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postQuit: async (
      assessmentId: string,
      player: AssessmentQuitDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists("postQuit", "assessmentId", assessmentId);
      // verify required parameter 'player' is not null or undefined
      assertParamExists("postQuit", "player", player);
      const localVarPath = `/api/v1/assessment/{assessment_id}/quit`.replace(
        `{${"assessment_id"}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        player,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AssessmentApi - functional programming interface
 * @export
 */
export const AssessmentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AssessmentApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete an Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async delAssessment(
      assessmentId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssessmentResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.delAssessment(
        assessmentId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get an Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssessment(
      assessmentId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssessmentResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessment(
        assessmentId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Assessments
     * @param {Array<'lobby' | 'inProgress' | 'finished'>} [assessmentStatusQuery] Assessment Status, default lobby
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssessments(
      assessmentStatusQuery?: Array<"lobby" | "inProgress" | "finished">,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssessmentsResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessments(
        assessmentStatusQuery,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Add Assessment Results
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentResultsDto} results Assessment results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAssessmentAdd(
      assessmentId: string,
      results: AssessmentResultsDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postAssessmentAdd(
          assessmentId,
          results,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Dislike players
     * @param {string} assessmentId Assessment UUID
     * @param {Dislikes} dislikeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postDislike(
      assessmentId: string,
      dislikeBody: Dislikes,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postDislike(
        assessmentId,
        dislikeBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Quit player from an Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentQuitDto} player Assessment results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postQuit(
      assessmentId: string,
      player: AssessmentQuitDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postQuit(
        assessmentId,
        player,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AssessmentApi - factory interface
 * @export
 */
export const AssessmentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AssessmentApiFp(configuration);
  return {
    /**
     *
     * @summary Delete an Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delAssessment(
      assessmentId: string,
      options?: any,
    ): AxiosPromise<AssessmentResDto> {
      return localVarFp
        .delAssessment(assessmentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get an Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssessment(
      assessmentId: string,
      options?: any,
    ): AxiosPromise<AssessmentResDto> {
      return localVarFp
        .getAssessment(assessmentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Assessments
     * @param {Array<'lobby' | 'inProgress' | 'finished'>} [assessmentStatusQuery] Assessment Status, default lobby
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssessments(
      assessmentStatusQuery?: Array<"lobby" | "inProgress" | "finished">,
      options?: any,
    ): AxiosPromise<AssessmentsResDto> {
      return localVarFp
        .getAssessments(assessmentStatusQuery, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add Assessment Results
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentResultsDto} results Assessment results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAssessmentAdd(
      assessmentId: string,
      results: AssessmentResultsDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postAssessmentAdd(assessmentId, results, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Dislike players
     * @param {string} assessmentId Assessment UUID
     * @param {Dislikes} dislikeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDislike(
      assessmentId: string,
      dislikeBody: Dislikes,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postDislike(assessmentId, dislikeBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Quit player from an Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentQuitDto} player Assessment results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postQuit(
      assessmentId: string,
      player: AssessmentQuitDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postQuit(assessmentId, player, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for delAssessment operation in AssessmentApi.
 * @export
 * @interface AssessmentApiDelAssessmentRequest
 */
export interface AssessmentApiDelAssessmentRequest {
  /**
   * Assessment UUID
   * @type {string}
   * @memberof AssessmentApiDelAssessment
   */
  readonly assessmentId: string;
}

/**
 * Request parameters for getAssessment operation in AssessmentApi.
 * @export
 * @interface AssessmentApiGetAssessmentRequest
 */
export interface AssessmentApiGetAssessmentRequest {
  /**
   * Assessment UUID
   * @type {string}
   * @memberof AssessmentApiGetAssessment
   */
  readonly assessmentId: string;
}

/**
 * Request parameters for getAssessments operation in AssessmentApi.
 * @export
 * @interface AssessmentApiGetAssessmentsRequest
 */
export interface AssessmentApiGetAssessmentsRequest {
  /**
   * Assessment Status, default lobby
   * @type {Array<'lobby' | 'inProgress' | 'finished'>}
   * @memberof AssessmentApiGetAssessments
   */
  readonly assessmentStatusQuery?: Array<"lobby" | "inProgress" | "finished">;
}

/**
 * Request parameters for postAssessmentAdd operation in AssessmentApi.
 * @export
 * @interface AssessmentApiPostAssessmentAddRequest
 */
export interface AssessmentApiPostAssessmentAddRequest {
  /**
   * Assessment UUID
   * @type {string}
   * @memberof AssessmentApiPostAssessmentAdd
   */
  readonly assessmentId: string;

  /**
   * Assessment results
   * @type {AssessmentResultsDto}
   * @memberof AssessmentApiPostAssessmentAdd
   */
  readonly results: AssessmentResultsDto;
}

/**
 * Request parameters for postDislike operation in AssessmentApi.
 * @export
 * @interface AssessmentApiPostDislikeRequest
 */
export interface AssessmentApiPostDislikeRequest {
  /**
   * Assessment UUID
   * @type {string}
   * @memberof AssessmentApiPostDislike
   */
  readonly assessmentId: string;

  /**
   *
   * @type {Dislikes}
   * @memberof AssessmentApiPostDislike
   */
  readonly dislikeBody: Dislikes;
}

/**
 * Request parameters for postQuit operation in AssessmentApi.
 * @export
 * @interface AssessmentApiPostQuitRequest
 */
export interface AssessmentApiPostQuitRequest {
  /**
   * Assessment UUID
   * @type {string}
   * @memberof AssessmentApiPostQuit
   */
  readonly assessmentId: string;

  /**
   * Assessment results
   * @type {AssessmentQuitDto}
   * @memberof AssessmentApiPostQuit
   */
  readonly player: AssessmentQuitDto;
}

/**
 * AssessmentApi - object-oriented interface
 * @export
 * @class AssessmentApi
 * @extends {BaseAPI}
 */
export class AssessmentApi extends BaseAPI {
  /**
   *
   * @summary Delete an Assessment
   * @param {AssessmentApiDelAssessmentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentApi
   */
  public delAssessment(
    requestParameters: AssessmentApiDelAssessmentRequest,
    options?: any,
  ) {
    return AssessmentApiFp(this.configuration)
      .delAssessment(requestParameters.assessmentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get an Assessment
   * @param {AssessmentApiGetAssessmentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentApi
   */
  public getAssessment(
    requestParameters: AssessmentApiGetAssessmentRequest,
    options?: any,
  ) {
    return AssessmentApiFp(this.configuration)
      .getAssessment(requestParameters.assessmentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Assessments
   * @param {AssessmentApiGetAssessmentsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentApi
   */
  public getAssessments(
    requestParameters: AssessmentApiGetAssessmentsRequest = {},
    options?: any,
  ) {
    return AssessmentApiFp(this.configuration)
      .getAssessments(requestParameters.assessmentStatusQuery, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add Assessment Results
   * @param {AssessmentApiPostAssessmentAddRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentApi
   */
  public postAssessmentAdd(
    requestParameters: AssessmentApiPostAssessmentAddRequest,
    options?: any,
  ) {
    return AssessmentApiFp(this.configuration)
      .postAssessmentAdd(
        requestParameters.assessmentId,
        requestParameters.results,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Dislike players
   * @param {AssessmentApiPostDislikeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentApi
   */
  public postDislike(
    requestParameters: AssessmentApiPostDislikeRequest,
    options?: any,
  ) {
    return AssessmentApiFp(this.configuration)
      .postDislike(
        requestParameters.assessmentId,
        requestParameters.dislikeBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Quit player from an Assessment
   * @param {AssessmentApiPostQuitRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentApi
   */
  public postQuit(
    requestParameters: AssessmentApiPostQuitRequest,
    options?: any,
  ) {
    return AssessmentApiFp(this.configuration)
      .postQuit(
        requestParameters.assessmentId,
        requestParameters.player,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AssessmentMembersApi - axios parameter creator
 * @export
 */
export const AssessmentMembersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Assessment get members
     * @param {string} assessmentId Assessment UUID
     * @param {string} [firstName] First Name
     * @param {string} [lastName] Last Name
     * @param {Array<string>} [locality] filter players by locality
     * @param {Array<string>} [phone] filter players by phone
     * @param {Array<string>} [workPhone] filter players by work phone
     * @param {Array<string>} [studyWorkPlace] filter players by studyWorkPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {Array<string>} [email] filter players by email
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGameAssessmentAddMembers: async (
      assessmentId: string,
      firstName?: string,
      lastName?: string,
      locality?: Array<string>,
      phone?: Array<string>,
      workPhone?: Array<string>,
      studyWorkPlace?: Array<string>,
      email?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        "getGameAssessmentAddMembers",
        "assessmentId",
        assessmentId,
      );
      const localVarPath = `/api/v1/assessment/members/{assessment_id}`.replace(
        `{${"assessment_id"}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      if (firstName !== undefined) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName !== undefined) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (locality) {
        localVarQueryParameter["locality"] = locality;
      }

      if (phone) {
        localVarQueryParameter["phone"] = phone;
      }

      if (workPhone) {
        localVarQueryParameter["workPhone"] = workPhone;
      }

      if (studyWorkPlace) {
        localVarQueryParameter["studyWorkPlace"] = studyWorkPlace;
      }

      if (email) {
        localVarQueryParameter["email"] = email;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Assessment patch members
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentMemberReqDto} members patch Assessment member
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchGameAssessmentAddMembers: async (
      assessmentId: string,
      members: AssessmentMemberReqDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        "patchGameAssessmentAddMembers",
        "assessmentId",
        assessmentId,
      );
      // verify required parameter 'members' is not null or undefined
      assertParamExists("patchGameAssessmentAddMembers", "members", members);
      const localVarPath = `/api/v1/assessment/members/{assessment_id}`.replace(
        `{${"assessment_id"}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        members,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Assessment add members
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentAddMembersReqDto} members Assessment members
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGameAssessmentAddMembers: async (
      assessmentId: string,
      members: AssessmentAddMembersReqDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        "postGameAssessmentAddMembers",
        "assessmentId",
        assessmentId,
      );
      // verify required parameter 'members' is not null or undefined
      assertParamExists("postGameAssessmentAddMembers", "members", members);
      const localVarPath = `/api/v1/assessment/members/{assessment_id}`.replace(
        `{${"assessment_id"}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        members,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AssessmentMembersApi - functional programming interface
 * @export
 */
export const AssessmentMembersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AssessmentMembersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Assessment get members
     * @param {string} assessmentId Assessment UUID
     * @param {string} [firstName] First Name
     * @param {string} [lastName] Last Name
     * @param {Array<string>} [locality] filter players by locality
     * @param {Array<string>} [phone] filter players by phone
     * @param {Array<string>} [workPhone] filter players by work phone
     * @param {Array<string>} [studyWorkPlace] filter players by studyWorkPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {Array<string>} [email] filter players by email
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGameAssessmentAddMembers(
      assessmentId: string,
      firstName?: string,
      lastName?: string,
      locality?: Array<string>,
      phone?: Array<string>,
      workPhone?: Array<string>,
      studyWorkPlace?: Array<string>,
      email?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssessmentAddMembersResDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getGameAssessmentAddMembers(
          assessmentId,
          firstName,
          lastName,
          locality,
          phone,
          workPhone,
          studyWorkPlace,
          email,
          pageNum,
          pageSize,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Assessment patch members
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentMemberReqDto} members patch Assessment member
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchGameAssessmentAddMembers(
      assessmentId: string,
      members: AssessmentMemberReqDto,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssessmentMemberResDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchGameAssessmentAddMembers(
          assessmentId,
          members,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Assessment add members
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentAddMembersReqDto} members Assessment members
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postGameAssessmentAddMembers(
      assessmentId: string,
      members: AssessmentAddMembersReqDto,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssessmentAddMembersResDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postGameAssessmentAddMembers(
          assessmentId,
          members,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AssessmentMembersApi - factory interface
 * @export
 */
export const AssessmentMembersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AssessmentMembersApiFp(configuration);
  return {
    /**
     *
     * @summary Assessment get members
     * @param {string} assessmentId Assessment UUID
     * @param {string} [firstName] First Name
     * @param {string} [lastName] Last Name
     * @param {Array<string>} [locality] filter players by locality
     * @param {Array<string>} [phone] filter players by phone
     * @param {Array<string>} [workPhone] filter players by work phone
     * @param {Array<string>} [studyWorkPlace] filter players by studyWorkPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {Array<string>} [email] filter players by email
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGameAssessmentAddMembers(
      assessmentId: string,
      firstName?: string,
      lastName?: string,
      locality?: Array<string>,
      phone?: Array<string>,
      workPhone?: Array<string>,
      studyWorkPlace?: Array<string>,
      email?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<AssessmentAddMembersResDto> {
      return localVarFp
        .getGameAssessmentAddMembers(
          assessmentId,
          firstName,
          lastName,
          locality,
          phone,
          workPhone,
          studyWorkPlace,
          email,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Assessment patch members
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentMemberReqDto} members patch Assessment member
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchGameAssessmentAddMembers(
      assessmentId: string,
      members: AssessmentMemberReqDto,
      options?: any,
    ): AxiosPromise<AssessmentMemberResDto> {
      return localVarFp
        .patchGameAssessmentAddMembers(assessmentId, members, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Assessment add members
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentAddMembersReqDto} members Assessment members
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGameAssessmentAddMembers(
      assessmentId: string,
      members: AssessmentAddMembersReqDto,
      options?: any,
    ): AxiosPromise<AssessmentAddMembersResDto> {
      return localVarFp
        .postGameAssessmentAddMembers(assessmentId, members, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getGameAssessmentAddMembers operation in AssessmentMembersApi.
 * @export
 * @interface AssessmentMembersApiGetGameAssessmentAddMembersRequest
 */
export interface AssessmentMembersApiGetGameAssessmentAddMembersRequest {
  /**
   * Assessment UUID
   * @type {string}
   * @memberof AssessmentMembersApiGetGameAssessmentAddMembers
   */
  readonly assessmentId: string;

  /**
   * First Name
   * @type {string}
   * @memberof AssessmentMembersApiGetGameAssessmentAddMembers
   */
  readonly firstName?: string;

  /**
   * Last Name
   * @type {string}
   * @memberof AssessmentMembersApiGetGameAssessmentAddMembers
   */
  readonly lastName?: string;

  /**
   * filter players by locality
   * @type {Array<string>}
   * @memberof AssessmentMembersApiGetGameAssessmentAddMembers
   */
  readonly locality?: Array<string>;

  /**
   * filter players by phone
   * @type {Array<string>}
   * @memberof AssessmentMembersApiGetGameAssessmentAddMembers
   */
  readonly phone?: Array<string>;

  /**
   * filter players by work phone
   * @type {Array<string>}
   * @memberof AssessmentMembersApiGetGameAssessmentAddMembers
   */
  readonly workPhone?: Array<string>;

  /**
   * filter players by studyWorkPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
   * @type {Array<string>}
   * @memberof AssessmentMembersApiGetGameAssessmentAddMembers
   */
  readonly studyWorkPlace?: Array<string>;

  /**
   * filter players by email
   * @type {Array<string>}
   * @memberof AssessmentMembersApiGetGameAssessmentAddMembers
   */
  readonly email?: Array<string>;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof AssessmentMembersApiGetGameAssessmentAddMembers
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof AssessmentMembersApiGetGameAssessmentAddMembers
   */
  readonly pageSize?: number;
}

/**
 * Request parameters for patchGameAssessmentAddMembers operation in AssessmentMembersApi.
 * @export
 * @interface AssessmentMembersApiPatchGameAssessmentAddMembersRequest
 */
export interface AssessmentMembersApiPatchGameAssessmentAddMembersRequest {
  /**
   * Assessment UUID
   * @type {string}
   * @memberof AssessmentMembersApiPatchGameAssessmentAddMembers
   */
  readonly assessmentId: string;

  /**
   * patch Assessment member
   * @type {AssessmentMemberReqDto}
   * @memberof AssessmentMembersApiPatchGameAssessmentAddMembers
   */
  readonly members: AssessmentMemberReqDto;
}

/**
 * Request parameters for postGameAssessmentAddMembers operation in AssessmentMembersApi.
 * @export
 * @interface AssessmentMembersApiPostGameAssessmentAddMembersRequest
 */
export interface AssessmentMembersApiPostGameAssessmentAddMembersRequest {
  /**
   * Assessment UUID
   * @type {string}
   * @memberof AssessmentMembersApiPostGameAssessmentAddMembers
   */
  readonly assessmentId: string;

  /**
   * Assessment members
   * @type {AssessmentAddMembersReqDto}
   * @memberof AssessmentMembersApiPostGameAssessmentAddMembers
   */
  readonly members: AssessmentAddMembersReqDto;
}

/**
 * AssessmentMembersApi - object-oriented interface
 * @export
 * @class AssessmentMembersApi
 * @extends {BaseAPI}
 */
export class AssessmentMembersApi extends BaseAPI {
  /**
   *
   * @summary Assessment get members
   * @param {AssessmentMembersApiGetGameAssessmentAddMembersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentMembersApi
   */
  public getGameAssessmentAddMembers(
    requestParameters: AssessmentMembersApiGetGameAssessmentAddMembersRequest,
    options?: any,
  ) {
    return AssessmentMembersApiFp(this.configuration)
      .getGameAssessmentAddMembers(
        requestParameters.assessmentId,
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.locality,
        requestParameters.phone,
        requestParameters.workPhone,
        requestParameters.studyWorkPlace,
        requestParameters.email,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Assessment patch members
   * @param {AssessmentMembersApiPatchGameAssessmentAddMembersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentMembersApi
   */
  public patchGameAssessmentAddMembers(
    requestParameters: AssessmentMembersApiPatchGameAssessmentAddMembersRequest,
    options?: any,
  ) {
    return AssessmentMembersApiFp(this.configuration)
      .patchGameAssessmentAddMembers(
        requestParameters.assessmentId,
        requestParameters.members,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Assessment add members
   * @param {AssessmentMembersApiPostGameAssessmentAddMembersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentMembersApi
   */
  public postGameAssessmentAddMembers(
    requestParameters: AssessmentMembersApiPostGameAssessmentAddMembersRequest,
    options?: any,
  ) {
    return AssessmentMembersApiFp(this.configuration)
      .postGameAssessmentAddMembers(
        requestParameters.assessmentId,
        requestParameters.members,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AssessmentValidateApi - axios parameter creator
 * @export
 */
export const AssessmentValidateApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Assessment validate
     * @param {string} assessmentId Assessment UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGameAssessmentValidate: async (
      assessmentId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        "getGameAssessmentValidate",
        "assessmentId",
        assessmentId,
      );
      const localVarPath = `/api/v1/validateAssessment/{assessment_id}`.replace(
        `{${"assessment_id"}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AssessmentValidateApi - functional programming interface
 * @export
 */
export const AssessmentValidateApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    AssessmentValidateApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Assessment validate
     * @param {string} assessmentId Assessment UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGameAssessmentValidate(
      assessmentId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssessmentValidateResDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getGameAssessmentValidate(
          assessmentId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AssessmentValidateApi - factory interface
 * @export
 */
export const AssessmentValidateApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AssessmentValidateApiFp(configuration);
  return {
    /**
     *
     * @summary Assessment validate
     * @param {string} assessmentId Assessment UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGameAssessmentValidate(
      assessmentId: string,
      options?: any,
    ): AxiosPromise<AssessmentValidateResDto> {
      return localVarFp
        .getGameAssessmentValidate(assessmentId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getGameAssessmentValidate operation in AssessmentValidateApi.
 * @export
 * @interface AssessmentValidateApiGetGameAssessmentValidateRequest
 */
export interface AssessmentValidateApiGetGameAssessmentValidateRequest {
  /**
   * Assessment UUID
   * @type {string}
   * @memberof AssessmentValidateApiGetGameAssessmentValidate
   */
  readonly assessmentId: string;
}

/**
 * AssessmentValidateApi - object-oriented interface
 * @export
 * @class AssessmentValidateApi
 * @extends {BaseAPI}
 */
export class AssessmentValidateApi extends BaseAPI {
  /**
   *
   * @summary Assessment validate
   * @param {AssessmentValidateApiGetGameAssessmentValidateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentValidateApi
   */
  public getGameAssessmentValidate(
    requestParameters: AssessmentValidateApiGetGameAssessmentValidateRequest,
    options?: any,
  ) {
    return AssessmentValidateApiFp(this.configuration)
      .getGameAssessmentValidate(requestParameters.assessmentId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HrGamesApi - axios parameter creator
 * @export
 */
export const HrGamesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get all short urls
     * @param {Array<string>} [assessmentIds] Список идентификаторов ассессментов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getShortUrls: async (
      assessmentIds?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/hr/games/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      if (assessmentIds) {
        localVarQueryParameter["assessment_ids"] = assessmentIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Hr Update Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {UpdateAssessmentReqDto} updateAssessment Assessment parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchHrUpdateAssessment: async (
      assessmentId: string,
      updateAssessment: UpdateAssessmentReqDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        "patchHrUpdateAssessment",
        "assessmentId",
        assessmentId,
      );
      // verify required parameter 'updateAssessment' is not null or undefined
      assertParamExists(
        "patchHrUpdateAssessment",
        "updateAssessment",
        updateAssessment,
      );
      const localVarPath =
        `/api/v1/hr/assessments/{assessment_id}/updateAssessment`.replace(
          `{${"assessment_id"}}`,
          encodeURIComponent(String(assessmentId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAssessment,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Hr Copy Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentCopyReqDto} assessmentCopyReqDtoBody Assessment Copy Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGameHrCopyAssessment: async (
      assessmentId: string,
      assessmentCopyReqDtoBody: AssessmentCopyReqDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        "postGameHrCopyAssessment",
        "assessmentId",
        assessmentId,
      );
      // verify required parameter 'assessmentCopyReqDtoBody' is not null or undefined
      assertParamExists(
        "postGameHrCopyAssessment",
        "assessmentCopyReqDtoBody",
        assessmentCopyReqDtoBody,
      );
      const localVarPath =
        `/api/v1/hr/games/{assessment_id}/copyAssessment`.replace(
          `{${"assessment_id"}}`,
          encodeURIComponent(String(assessmentId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        assessmentCopyReqDtoBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Hr Create Assessment
     * @param {string} gameId Simulation Game ID
     * @param {CreateAssessmentReqDto} createAssessment Assessment parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGameHrCreateAssessment: async (
      gameId: string,
      createAssessment: CreateAssessmentReqDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("postGameHrCreateAssessment", "gameId", gameId);
      // verify required parameter 'createAssessment' is not null or undefined
      assertParamExists(
        "postGameHrCreateAssessment",
        "createAssessment",
        createAssessment,
      );
      const localVarPath =
        `/api/v1/hr/games/{game_id}/createAssessment`.replace(
          `{${"game_id"}}`,
          encodeURIComponent(String(gameId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAssessment,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HrGamesApi - functional programming interface
 * @export
 */
export const HrGamesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HrGamesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get all short urls
     * @param {Array<string>} [assessmentIds] Список идентификаторов ассессментов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getShortUrls(
      assessmentIds?: Array<string>,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getShortUrls(
        assessmentIds,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Hr Update Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {UpdateAssessmentReqDto} updateAssessment Assessment parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchHrUpdateAssessment(
      assessmentId: string,
      updateAssessment: UpdateAssessmentReqDto,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateAssessmentResDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchHrUpdateAssessment(
          assessmentId,
          updateAssessment,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Hr Copy Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentCopyReqDto} assessmentCopyReqDtoBody Assessment Copy Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postGameHrCopyAssessment(
      assessmentId: string,
      assessmentCopyReqDtoBody: AssessmentCopyReqDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postGameHrCopyAssessment(
          assessmentId,
          assessmentCopyReqDtoBody,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Hr Create Assessment
     * @param {string} gameId Simulation Game ID
     * @param {CreateAssessmentReqDto} createAssessment Assessment parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postGameHrCreateAssessment(
      gameId: string,
      createAssessment: CreateAssessmentReqDto,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateAssessmentResDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postGameHrCreateAssessment(
          gameId,
          createAssessment,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * HrGamesApi - factory interface
 * @export
 */
export const HrGamesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HrGamesApiFp(configuration);
  return {
    /**
     *
     * @summary Get all short urls
     * @param {Array<string>} [assessmentIds] Список идентификаторов ассессментов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getShortUrls(
      assessmentIds?: Array<string>,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .getShortUrls(assessmentIds, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Hr Update Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {UpdateAssessmentReqDto} updateAssessment Assessment parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchHrUpdateAssessment(
      assessmentId: string,
      updateAssessment: UpdateAssessmentReqDto,
      options?: any,
    ): AxiosPromise<UpdateAssessmentResDto> {
      return localVarFp
        .patchHrUpdateAssessment(assessmentId, updateAssessment, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Hr Copy Assessment
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentCopyReqDto} assessmentCopyReqDtoBody Assessment Copy Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGameHrCopyAssessment(
      assessmentId: string,
      assessmentCopyReqDtoBody: AssessmentCopyReqDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postGameHrCopyAssessment(
          assessmentId,
          assessmentCopyReqDtoBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Hr Create Assessment
     * @param {string} gameId Simulation Game ID
     * @param {CreateAssessmentReqDto} createAssessment Assessment parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGameHrCreateAssessment(
      gameId: string,
      createAssessment: CreateAssessmentReqDto,
      options?: any,
    ): AxiosPromise<CreateAssessmentResDto> {
      return localVarFp
        .postGameHrCreateAssessment(gameId, createAssessment, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getShortUrls operation in HrGamesApi.
 * @export
 * @interface HrGamesApiGetShortUrlsRequest
 */
export interface HrGamesApiGetShortUrlsRequest {
  /**
   * Список идентификаторов ассессментов
   * @type {Array<string>}
   * @memberof HrGamesApiGetShortUrls
   */
  readonly assessmentIds?: Array<string>;
}

/**
 * Request parameters for patchHrUpdateAssessment operation in HrGamesApi.
 * @export
 * @interface HrGamesApiPatchHrUpdateAssessmentRequest
 */
export interface HrGamesApiPatchHrUpdateAssessmentRequest {
  /**
   * Assessment UUID
   * @type {string}
   * @memberof HrGamesApiPatchHrUpdateAssessment
   */
  readonly assessmentId: string;

  /**
   * Assessment parameters
   * @type {UpdateAssessmentReqDto}
   * @memberof HrGamesApiPatchHrUpdateAssessment
   */
  readonly updateAssessment: UpdateAssessmentReqDto;
}

/**
 * Request parameters for postGameHrCopyAssessment operation in HrGamesApi.
 * @export
 * @interface HrGamesApiPostGameHrCopyAssessmentRequest
 */
export interface HrGamesApiPostGameHrCopyAssessmentRequest {
  /**
   * Assessment UUID
   * @type {string}
   * @memberof HrGamesApiPostGameHrCopyAssessment
   */
  readonly assessmentId: string;

  /**
   * Assessment Copy Info
   * @type {AssessmentCopyReqDto}
   * @memberof HrGamesApiPostGameHrCopyAssessment
   */
  readonly assessmentCopyReqDtoBody: AssessmentCopyReqDto;
}

/**
 * Request parameters for postGameHrCreateAssessment operation in HrGamesApi.
 * @export
 * @interface HrGamesApiPostGameHrCreateAssessmentRequest
 */
export interface HrGamesApiPostGameHrCreateAssessmentRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof HrGamesApiPostGameHrCreateAssessment
   */
  readonly gameId: string;

  /**
   * Assessment parameters
   * @type {CreateAssessmentReqDto}
   * @memberof HrGamesApiPostGameHrCreateAssessment
   */
  readonly createAssessment: CreateAssessmentReqDto;
}

/**
 * HrGamesApi - object-oriented interface
 * @export
 * @class HrGamesApi
 * @extends {BaseAPI}
 */
export class HrGamesApi extends BaseAPI {
  /**
   *
   * @summary Get all short urls
   * @param {HrGamesApiGetShortUrlsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HrGamesApi
   */
  public getShortUrls(
    requestParameters: HrGamesApiGetShortUrlsRequest = {},
    options?: any,
  ) {
    return HrGamesApiFp(this.configuration)
      .getShortUrls(requestParameters.assessmentIds, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Hr Update Assessment
   * @param {HrGamesApiPatchHrUpdateAssessmentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HrGamesApi
   */
  public patchHrUpdateAssessment(
    requestParameters: HrGamesApiPatchHrUpdateAssessmentRequest,
    options?: any,
  ) {
    return HrGamesApiFp(this.configuration)
      .patchHrUpdateAssessment(
        requestParameters.assessmentId,
        requestParameters.updateAssessment,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Hr Copy Assessment
   * @param {HrGamesApiPostGameHrCopyAssessmentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HrGamesApi
   */
  public postGameHrCopyAssessment(
    requestParameters: HrGamesApiPostGameHrCopyAssessmentRequest,
    options?: any,
  ) {
    return HrGamesApiFp(this.configuration)
      .postGameHrCopyAssessment(
        requestParameters.assessmentId,
        requestParameters.assessmentCopyReqDtoBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Hr Create Assessment
   * @param {HrGamesApiPostGameHrCreateAssessmentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HrGamesApi
   */
  public postGameHrCreateAssessment(
    requestParameters: HrGamesApiPostGameHrCreateAssessmentRequest,
    options?: any,
  ) {
    return HrGamesApiFp(this.configuration)
      .postGameHrCreateAssessment(
        requestParameters.gameId,
        requestParameters.createAssessment,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PlayerGamesApi - axios parameter creator
 * @export
 */
export const PlayerGamesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Game Info
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPGame: async (
      gameId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("getPGame", "gameId", gameId);
      const localVarPath = `/api/v1/player/games/{game_id}`.replace(
        `{${"game_id"}}`,
        encodeURIComponent(String(gameId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get available Games List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPGames: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/player/games`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Assessment
     * @param {string} gameId Simulation Game ID
     * @param {CreateAssessmentReqDto} createAssessment Assessment parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGameCreateAssessment: async (
      gameId: string,
      createAssessment: CreateAssessmentReqDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gameId' is not null or undefined
      assertParamExists("postGameCreateAssessment", "gameId", gameId);
      // verify required parameter 'createAssessment' is not null or undefined
      assertParamExists(
        "postGameCreateAssessment",
        "createAssessment",
        createAssessment,
      );
      const localVarPath =
        `/api/v1/player/games/{game_id}/createAssessment`.replace(
          `{${"game_id"}}`,
          encodeURIComponent(String(gameId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAssessment,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Assessment for player Level Up
     * @param {'en' | 'ru'} [localeQuery] Title locales
     * @param {PLvlUpCompetence} [competencies] Competence for levelUp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLevelUp: async (
      localeQuery?: "en" | "ru",
      competencies?: PLvlUpCompetence,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/player/games/levelUp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      if (localeQuery !== undefined) {
        localVarQueryParameter["localeQuery"] = localeQuery;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        competencies,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlayerGamesApi - functional programming interface
 * @export
 */
export const PlayerGamesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PlayerGamesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Game Info
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPGame(
      gameId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PGameResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPGame(
        gameId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get available Games List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPGames(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PGamesResDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPGames(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create Assessment
     * @param {string} gameId Simulation Game ID
     * @param {CreateAssessmentReqDto} createAssessment Assessment parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postGameCreateAssessment(
      gameId: string,
      createAssessment: CreateAssessmentReqDto,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateAssessmentResDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postGameCreateAssessment(
          gameId,
          createAssessment,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create Assessment for player Level Up
     * @param {'en' | 'ru'} [localeQuery] Title locales
     * @param {PLvlUpCompetence} [competencies] Competence for levelUp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postLevelUp(
      localeQuery?: "en" | "ru",
      competencies?: PLvlUpCompetence,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LevelUpResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postLevelUp(
        localeQuery,
        competencies,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PlayerGamesApi - factory interface
 * @export
 */
export const PlayerGamesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PlayerGamesApiFp(configuration);
  return {
    /**
     *
     * @summary Get Game Info
     * @param {string} gameId Simulation Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPGame(gameId: string, options?: any): AxiosPromise<PGameResDto> {
      return localVarFp
        .getPGame(gameId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get available Games List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPGames(options?: any): AxiosPromise<PGamesResDto> {
      return localVarFp
        .getPGames(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create Assessment
     * @param {string} gameId Simulation Game ID
     * @param {CreateAssessmentReqDto} createAssessment Assessment parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGameCreateAssessment(
      gameId: string,
      createAssessment: CreateAssessmentReqDto,
      options?: any,
    ): AxiosPromise<CreateAssessmentResDto> {
      return localVarFp
        .postGameCreateAssessment(gameId, createAssessment, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create Assessment for player Level Up
     * @param {'en' | 'ru'} [localeQuery] Title locales
     * @param {PLvlUpCompetence} [competencies] Competence for levelUp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLevelUp(
      localeQuery?: "en" | "ru",
      competencies?: PLvlUpCompetence,
      options?: any,
    ): AxiosPromise<LevelUpResDto> {
      return localVarFp
        .postLevelUp(localeQuery, competencies, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getPGame operation in PlayerGamesApi.
 * @export
 * @interface PlayerGamesApiGetPGameRequest
 */
export interface PlayerGamesApiGetPGameRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof PlayerGamesApiGetPGame
   */
  readonly gameId: string;
}

/**
 * Request parameters for postGameCreateAssessment operation in PlayerGamesApi.
 * @export
 * @interface PlayerGamesApiPostGameCreateAssessmentRequest
 */
export interface PlayerGamesApiPostGameCreateAssessmentRequest {
  /**
   * Simulation Game ID
   * @type {string}
   * @memberof PlayerGamesApiPostGameCreateAssessment
   */
  readonly gameId: string;

  /**
   * Assessment parameters
   * @type {CreateAssessmentReqDto}
   * @memberof PlayerGamesApiPostGameCreateAssessment
   */
  readonly createAssessment: CreateAssessmentReqDto;
}

/**
 * Request parameters for postLevelUp operation in PlayerGamesApi.
 * @export
 * @interface PlayerGamesApiPostLevelUpRequest
 */
export interface PlayerGamesApiPostLevelUpRequest {
  /**
   * Title locales
   * @type {'en' | 'ru'}
   * @memberof PlayerGamesApiPostLevelUp
   */
  readonly localeQuery?: "en" | "ru";

  /**
   * Competence for levelUp
   * @type {PLvlUpCompetence}
   * @memberof PlayerGamesApiPostLevelUp
   */
  readonly competencies?: PLvlUpCompetence;
}

/**
 * PlayerGamesApi - object-oriented interface
 * @export
 * @class PlayerGamesApi
 * @extends {BaseAPI}
 */
export class PlayerGamesApi extends BaseAPI {
  /**
   *
   * @summary Get Game Info
   * @param {PlayerGamesApiGetPGameRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerGamesApi
   */
  public getPGame(
    requestParameters: PlayerGamesApiGetPGameRequest,
    options?: any,
  ) {
    return PlayerGamesApiFp(this.configuration)
      .getPGame(requestParameters.gameId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get available Games List
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerGamesApi
   */
  public getPGames(options?: any) {
    return PlayerGamesApiFp(this.configuration)
      .getPGames(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Assessment
   * @param {PlayerGamesApiPostGameCreateAssessmentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerGamesApi
   */
  public postGameCreateAssessment(
    requestParameters: PlayerGamesApiPostGameCreateAssessmentRequest,
    options?: any,
  ) {
    return PlayerGamesApiFp(this.configuration)
      .postGameCreateAssessment(
        requestParameters.gameId,
        requestParameters.createAssessment,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Assessment for player Level Up
   * @param {PlayerGamesApiPostLevelUpRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerGamesApi
   */
  public postLevelUp(
    requestParameters: PlayerGamesApiPostLevelUpRequest = {},
    options?: any,
  ) {
    return PlayerGamesApiFp(this.configuration)
      .postLevelUp(
        requestParameters.localeQuery,
        requestParameters.competencies,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PlayerTutorialHistoryApi - axios parameter creator
 * @export
 */
export const PlayerTutorialHistoryApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get PlayerTutorialHistory
     * @param {string} playerId Player UUID
     * @param {string} [chapterNumber]
     * @param {Array<'ALABUGA_START_TUTORIAL' | 'BUSINESS_CATS_TUTORIAL' | 'BUSINESS_CATS_TUTORIAL_LTD' | 'NPV_TUTORIAL' | 'MICROELECTRONICS_TUTORIAL' | 'TRUCKS_TUTORIAL'>} [simulationType] Game Type, default all
     * @param {number} [attemptFrom] Started At From Attempt
     * @param {number} [attemptTo] Started At To Attempt
     * @param {string} [dateOfCompleteFrom]
     * @param {string} [dateOfCompleteTo]
     * @param {string} [orderByQuery] sort assessments by fields gameTitle/titleQuery/templateTitle/code/ /assessmentType/assessmentStatus/curPlayers/maxPlayers/created /started/finished, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerTutorialHistory: async (
      playerId: string,
      chapterNumber?: string,
      simulationType?: Array<
        | "ALABUGA_START_TUTORIAL"
        | "BUSINESS_CATS_TUTORIAL"
        | "BUSINESS_CATS_TUTORIAL_LTD"
        | "NPV_TUTORIAL"
        | "MICROELECTRONICS_TUTORIAL"
        | "TRUCKS_TUTORIAL"
      >,
      attemptFrom?: number,
      attemptTo?: number,
      dateOfCompleteFrom?: string,
      dateOfCompleteTo?: string,
      orderByQuery?: string,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'playerId' is not null or undefined
      assertParamExists("getPlayerTutorialHistory", "playerId", playerId);
      const localVarPath =
        `/api/v1/player_tutorial_history/{player_id}`.replace(
          `{${"player_id"}}`,
          encodeURIComponent(String(playerId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      if (chapterNumber !== undefined) {
        localVarQueryParameter["chapterNumber"] = chapterNumber;
      }

      if (simulationType) {
        localVarQueryParameter["simulationType"] = simulationType;
      }

      if (attemptFrom !== undefined) {
        localVarQueryParameter["attemptFrom"] = attemptFrom;
      }

      if (attemptTo !== undefined) {
        localVarQueryParameter["attemptTo"] = attemptTo;
      }

      if (dateOfCompleteFrom !== undefined) {
        localVarQueryParameter["dateOfCompleteFrom"] =
          (dateOfCompleteFrom as any) instanceof Date
            ? (dateOfCompleteFrom as any).toISOString()
            : dateOfCompleteFrom;
      }

      if (dateOfCompleteTo !== undefined) {
        localVarQueryParameter["dateOfCompleteTo"] =
          (dateOfCompleteTo as any) instanceof Date
            ? (dateOfCompleteTo as any).toISOString()
            : dateOfCompleteTo;
      }

      if (orderByQuery !== undefined) {
        localVarQueryParameter["orderByQuery"] = orderByQuery;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlayerTutorialHistoryApi - functional programming interface
 * @export
 */
export const PlayerTutorialHistoryApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PlayerTutorialHistoryApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get PlayerTutorialHistory
     * @param {string} playerId Player UUID
     * @param {string} [chapterNumber]
     * @param {Array<'ALABUGA_START_TUTORIAL' | 'BUSINESS_CATS_TUTORIAL' | 'BUSINESS_CATS_TUTORIAL_LTD' | 'NPV_TUTORIAL' | 'MICROELECTRONICS_TUTORIAL' | 'TRUCKS_TUTORIAL'>} [simulationType] Game Type, default all
     * @param {number} [attemptFrom] Started At From Attempt
     * @param {number} [attemptTo] Started At To Attempt
     * @param {string} [dateOfCompleteFrom]
     * @param {string} [dateOfCompleteTo]
     * @param {string} [orderByQuery] sort assessments by fields gameTitle/titleQuery/templateTitle/code/ /assessmentType/assessmentStatus/curPlayers/maxPlayers/created /started/finished, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlayerTutorialHistory(
      playerId: string,
      chapterNumber?: string,
      simulationType?: Array<
        | "ALABUGA_START_TUTORIAL"
        | "BUSINESS_CATS_TUTORIAL"
        | "BUSINESS_CATS_TUTORIAL_LTD"
        | "NPV_TUTORIAL"
        | "MICROELECTRONICS_TUTORIAL"
        | "TRUCKS_TUTORIAL"
      >,
      attemptFrom?: number,
      attemptTo?: number,
      dateOfCompleteFrom?: string,
      dateOfCompleteTo?: string,
      orderByQuery?: string,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PlayerTutorialHistoryResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPlayerTutorialHistory(
          playerId,
          chapterNumber,
          simulationType,
          attemptFrom,
          attemptTo,
          dateOfCompleteFrom,
          dateOfCompleteTo,
          orderByQuery,
          pageNum,
          pageSize,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PlayerTutorialHistoryApi - factory interface
 * @export
 */
export const PlayerTutorialHistoryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PlayerTutorialHistoryApiFp(configuration);
  return {
    /**
     *
     * @summary Get PlayerTutorialHistory
     * @param {string} playerId Player UUID
     * @param {string} [chapterNumber]
     * @param {Array<'ALABUGA_START_TUTORIAL' | 'BUSINESS_CATS_TUTORIAL' | 'BUSINESS_CATS_TUTORIAL_LTD' | 'NPV_TUTORIAL' | 'MICROELECTRONICS_TUTORIAL' | 'TRUCKS_TUTORIAL'>} [simulationType] Game Type, default all
     * @param {number} [attemptFrom] Started At From Attempt
     * @param {number} [attemptTo] Started At To Attempt
     * @param {string} [dateOfCompleteFrom]
     * @param {string} [dateOfCompleteTo]
     * @param {string} [orderByQuery] sort assessments by fields gameTitle/titleQuery/templateTitle/code/ /assessmentType/assessmentStatus/curPlayers/maxPlayers/created /started/finished, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerTutorialHistory(
      playerId: string,
      chapterNumber?: string,
      simulationType?: Array<
        | "ALABUGA_START_TUTORIAL"
        | "BUSINESS_CATS_TUTORIAL"
        | "BUSINESS_CATS_TUTORIAL_LTD"
        | "NPV_TUTORIAL"
        | "MICROELECTRONICS_TUTORIAL"
        | "TRUCKS_TUTORIAL"
      >,
      attemptFrom?: number,
      attemptTo?: number,
      dateOfCompleteFrom?: string,
      dateOfCompleteTo?: string,
      orderByQuery?: string,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<PlayerTutorialHistoryResponse> {
      return localVarFp
        .getPlayerTutorialHistory(
          playerId,
          chapterNumber,
          simulationType,
          attemptFrom,
          attemptTo,
          dateOfCompleteFrom,
          dateOfCompleteTo,
          orderByQuery,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getPlayerTutorialHistory operation in PlayerTutorialHistoryApi.
 * @export
 * @interface PlayerTutorialHistoryApiGetPlayerTutorialHistoryRequest
 */
export interface PlayerTutorialHistoryApiGetPlayerTutorialHistoryRequest {
  /**
   * Player UUID
   * @type {string}
   * @memberof PlayerTutorialHistoryApiGetPlayerTutorialHistory
   */
  readonly playerId: string;

  /**
   *
   * @type {string}
   * @memberof PlayerTutorialHistoryApiGetPlayerTutorialHistory
   */
  readonly chapterNumber?: string;

  /**
   * Game Type, default all
   * @type {Array<'ALABUGA_START_TUTORIAL' | 'BUSINESS_CATS_TUTORIAL' | 'BUSINESS_CATS_TUTORIAL_LTD' | 'NPV_TUTORIAL' | 'MICROELECTRONICS_TUTORIAL' | 'TRUCKS_TUTORIAL'>}
   * @memberof PlayerTutorialHistoryApiGetPlayerTutorialHistory
   */
  readonly simulationType?: Array<
    | "ALABUGA_START_TUTORIAL"
    | "BUSINESS_CATS_TUTORIAL"
    | "BUSINESS_CATS_TUTORIAL_LTD"
    | "NPV_TUTORIAL"
    | "MICROELECTRONICS_TUTORIAL"
    | "TRUCKS_TUTORIAL"
  >;

  /**
   * Started At From Attempt
   * @type {number}
   * @memberof PlayerTutorialHistoryApiGetPlayerTutorialHistory
   */
  readonly attemptFrom?: number;

  /**
   * Started At To Attempt
   * @type {number}
   * @memberof PlayerTutorialHistoryApiGetPlayerTutorialHistory
   */
  readonly attemptTo?: number;

  /**
   *
   * @type {string}
   * @memberof PlayerTutorialHistoryApiGetPlayerTutorialHistory
   */
  readonly dateOfCompleteFrom?: string;

  /**
   *
   * @type {string}
   * @memberof PlayerTutorialHistoryApiGetPlayerTutorialHistory
   */
  readonly dateOfCompleteTo?: string;

  /**
   * sort assessments by fields gameTitle/titleQuery/templateTitle/code/ /assessmentType/assessmentStatus/curPlayers/maxPlayers/created /started/finished, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
   * @type {string}
   * @memberof PlayerTutorialHistoryApiGetPlayerTutorialHistory
   */
  readonly orderByQuery?: string;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof PlayerTutorialHistoryApiGetPlayerTutorialHistory
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof PlayerTutorialHistoryApiGetPlayerTutorialHistory
   */
  readonly pageSize?: number;
}

/**
 * PlayerTutorialHistoryApi - object-oriented interface
 * @export
 * @class PlayerTutorialHistoryApi
 * @extends {BaseAPI}
 */
export class PlayerTutorialHistoryApi extends BaseAPI {
  /**
   *
   * @summary Get PlayerTutorialHistory
   * @param {PlayerTutorialHistoryApiGetPlayerTutorialHistoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerTutorialHistoryApi
   */
  public getPlayerTutorialHistory(
    requestParameters: PlayerTutorialHistoryApiGetPlayerTutorialHistoryRequest,
    options?: any,
  ) {
    return PlayerTutorialHistoryApiFp(this.configuration)
      .getPlayerTutorialHistory(
        requestParameters.playerId,
        requestParameters.chapterNumber,
        requestParameters.simulationType,
        requestParameters.attemptFrom,
        requestParameters.attemptTo,
        requestParameters.dateOfCompleteFrom,
        requestParameters.dateOfCompleteTo,
        requestParameters.orderByQuery,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PostDeletedAssessmentMembersApi - axios parameter creator
 * @export
 */
export const PostDeletedAssessmentMembersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Asessment delete members
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentAddMembersReqDto} members Assessment members
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDeletedGameAssessmentMembers: async (
      assessmentId: string,
      members: AssessmentAddMembersReqDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        "postDeletedGameAssessmentMembers",
        "assessmentId",
        assessmentId,
      );
      // verify required parameter 'members' is not null or undefined
      assertParamExists("postDeletedGameAssessmentMembers", "members", members);
      const localVarPath =
        `/api/v1/assessment/members_delete/{assessment_id}`.replace(
          `{${"assessment_id"}}`,
          encodeURIComponent(String(assessmentId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        members,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PostDeletedAssessmentMembersApi - functional programming interface
 * @export
 */
export const PostDeletedAssessmentMembersApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PostDeletedAssessmentMembersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Asessment delete members
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentAddMembersReqDto} members Assessment members
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postDeletedGameAssessmentMembers(
      assessmentId: string,
      members: AssessmentAddMembersReqDto,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssessmentAddMembersResDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postDeletedGameAssessmentMembers(
          assessmentId,
          members,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PostDeletedAssessmentMembersApi - factory interface
 * @export
 */
export const PostDeletedAssessmentMembersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PostDeletedAssessmentMembersApiFp(configuration);
  return {
    /**
     *
     * @summary Asessment delete members
     * @param {string} assessmentId Assessment UUID
     * @param {AssessmentAddMembersReqDto} members Assessment members
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDeletedGameAssessmentMembers(
      assessmentId: string,
      members: AssessmentAddMembersReqDto,
      options?: any,
    ): AxiosPromise<AssessmentAddMembersResDto> {
      return localVarFp
        .postDeletedGameAssessmentMembers(assessmentId, members, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for postDeletedGameAssessmentMembers operation in PostDeletedAssessmentMembersApi.
 * @export
 * @interface PostDeletedAssessmentMembersApiPostDeletedGameAssessmentMembersRequest
 */
export interface PostDeletedAssessmentMembersApiPostDeletedGameAssessmentMembersRequest {
  /**
   * Assessment UUID
   * @type {string}
   * @memberof PostDeletedAssessmentMembersApiPostDeletedGameAssessmentMembers
   */
  readonly assessmentId: string;

  /**
   * Assessment members
   * @type {AssessmentAddMembersReqDto}
   * @memberof PostDeletedAssessmentMembersApiPostDeletedGameAssessmentMembers
   */
  readonly members: AssessmentAddMembersReqDto;
}

/**
 * PostDeletedAssessmentMembersApi - object-oriented interface
 * @export
 * @class PostDeletedAssessmentMembersApi
 * @extends {BaseAPI}
 */
export class PostDeletedAssessmentMembersApi extends BaseAPI {
  /**
   *
   * @summary Asessment delete members
   * @param {PostDeletedAssessmentMembersApiPostDeletedGameAssessmentMembersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostDeletedAssessmentMembersApi
   */
  public postDeletedGameAssessmentMembers(
    requestParameters: PostDeletedAssessmentMembersApiPostDeletedGameAssessmentMembersRequest,
    options?: any,
  ) {
    return PostDeletedAssessmentMembersApiFp(this.configuration)
      .postDeletedGameAssessmentMembers(
        requestParameters.assessmentId,
        requestParameters.members,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TutorialApi - axios parameter creator
 * @export
 */
export const TutorialApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Tutorial State
     * @param {string} playerId Player UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTutorial: async (
      playerId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'playerId' is not null or undefined
      assertParamExists("getTutorial", "playerId", playerId);
      const localVarPath = `/api/v1/tutorial/{player_id}`.replace(
        `{${"player_id"}}`,
        encodeURIComponent(String(playerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post Tutorial State
     * @param {string} playerId Player UUID
     * @param {TutorialDto} tutorial Player tutorial state data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTutorial: async (
      playerId: string,
      tutorial: TutorialDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'playerId' is not null or undefined
      assertParamExists("postTutorial", "playerId", playerId);
      // verify required parameter 'tutorial' is not null or undefined
      assertParamExists("postTutorial", "tutorial", tutorial);
      const localVarPath = `/api/v1/tutorial/{player_id}`.replace(
        `{${"player_id"}}`,
        encodeURIComponent(String(playerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tutorial,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TutorialApi - functional programming interface
 * @export
 */
export const TutorialApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TutorialApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Tutorial State
     * @param {string} playerId Player UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTutorial(
      playerId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TutorialDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTutorial(
        playerId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Post Tutorial State
     * @param {string} playerId Player UUID
     * @param {TutorialDto} tutorial Player tutorial state data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTutorial(
      playerId: string,
      tutorial: TutorialDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TutorialDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postTutorial(
        playerId,
        tutorial,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TutorialApi - factory interface
 * @export
 */
export const TutorialApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TutorialApiFp(configuration);
  return {
    /**
     *
     * @summary Get Tutorial State
     * @param {string} playerId Player UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTutorial(playerId: string, options?: any): AxiosPromise<TutorialDto> {
      return localVarFp
        .getTutorial(playerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Post Tutorial State
     * @param {string} playerId Player UUID
     * @param {TutorialDto} tutorial Player tutorial state data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTutorial(
      playerId: string,
      tutorial: TutorialDto,
      options?: any,
    ): AxiosPromise<TutorialDto> {
      return localVarFp
        .postTutorial(playerId, tutorial, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getTutorial operation in TutorialApi.
 * @export
 * @interface TutorialApiGetTutorialRequest
 */
export interface TutorialApiGetTutorialRequest {
  /**
   * Player UUID
   * @type {string}
   * @memberof TutorialApiGetTutorial
   */
  readonly playerId: string;
}

/**
 * Request parameters for postTutorial operation in TutorialApi.
 * @export
 * @interface TutorialApiPostTutorialRequest
 */
export interface TutorialApiPostTutorialRequest {
  /**
   * Player UUID
   * @type {string}
   * @memberof TutorialApiPostTutorial
   */
  readonly playerId: string;

  /**
   * Player tutorial state data
   * @type {TutorialDto}
   * @memberof TutorialApiPostTutorial
   */
  readonly tutorial: TutorialDto;
}

/**
 * TutorialApi - object-oriented interface
 * @export
 * @class TutorialApi
 * @extends {BaseAPI}
 */
export class TutorialApi extends BaseAPI {
  /**
   *
   * @summary Get Tutorial State
   * @param {TutorialApiGetTutorialRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TutorialApi
   */
  public getTutorial(
    requestParameters: TutorialApiGetTutorialRequest,
    options?: any,
  ) {
    return TutorialApiFp(this.configuration)
      .getTutorial(requestParameters.playerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post Tutorial State
   * @param {TutorialApiPostTutorialRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TutorialApi
   */
  public postTutorial(
    requestParameters: TutorialApiPostTutorialRequest,
    options?: any,
  ) {
    return TutorialApiFp(this.configuration)
      .postTutorial(
        requestParameters.playerId,
        requestParameters.tutorial,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TutorialLtdApi - axios parameter creator
 * @export
 */
export const TutorialLtdApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Tutorial LTD State
     * @param {string} playerId Player UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTutorialLtd: async (
      playerId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'playerId' is not null or undefined
      assertParamExists("getTutorialLtd", "playerId", playerId);
      const localVarPath = `/api/v1/tutorial_ltd/{player_id}`.replace(
        `{${"player_id"}}`,
        encodeURIComponent(String(playerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post Tutorial Ltd State
     * @param {string} playerId Player UUID
     * @param {TutorialDto} tutorial Player tutorial state data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTutorialLtd: async (
      playerId: string,
      tutorial: TutorialDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'playerId' is not null or undefined
      assertParamExists("postTutorialLtd", "playerId", playerId);
      // verify required parameter 'tutorial' is not null or undefined
      assertParamExists("postTutorialLtd", "tutorial", tutorial);
      const localVarPath = `/api/v1/tutorial_ltd/{player_id}`.replace(
        `{${"player_id"}}`,
        encodeURIComponent(String(playerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tutorial,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TutorialLtdApi - functional programming interface
 * @export
 */
export const TutorialLtdApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    TutorialLtdApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Tutorial LTD State
     * @param {string} playerId Player UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTutorialLtd(
      playerId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TutorialLtdDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTutorialLtd(
        playerId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Post Tutorial Ltd State
     * @param {string} playerId Player UUID
     * @param {TutorialDto} tutorial Player tutorial state data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTutorialLtd(
      playerId: string,
      tutorial: TutorialDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TutorialLtdDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postTutorialLtd(
        playerId,
        tutorial,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TutorialLtdApi - factory interface
 * @export
 */
export const TutorialLtdApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TutorialLtdApiFp(configuration);
  return {
    /**
     *
     * @summary Get Tutorial LTD State
     * @param {string} playerId Player UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTutorialLtd(
      playerId: string,
      options?: any,
    ): AxiosPromise<TutorialLtdDto> {
      return localVarFp
        .getTutorialLtd(playerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Post Tutorial Ltd State
     * @param {string} playerId Player UUID
     * @param {TutorialDto} tutorial Player tutorial state data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTutorialLtd(
      playerId: string,
      tutorial: TutorialDto,
      options?: any,
    ): AxiosPromise<TutorialLtdDto> {
      return localVarFp
        .postTutorialLtd(playerId, tutorial, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getTutorialLtd operation in TutorialLtdApi.
 * @export
 * @interface TutorialLtdApiGetTutorialLtdRequest
 */
export interface TutorialLtdApiGetTutorialLtdRequest {
  /**
   * Player UUID
   * @type {string}
   * @memberof TutorialLtdApiGetTutorialLtd
   */
  readonly playerId: string;
}

/**
 * Request parameters for postTutorialLtd operation in TutorialLtdApi.
 * @export
 * @interface TutorialLtdApiPostTutorialLtdRequest
 */
export interface TutorialLtdApiPostTutorialLtdRequest {
  /**
   * Player UUID
   * @type {string}
   * @memberof TutorialLtdApiPostTutorialLtd
   */
  readonly playerId: string;

  /**
   * Player tutorial state data
   * @type {TutorialDto}
   * @memberof TutorialLtdApiPostTutorialLtd
   */
  readonly tutorial: TutorialDto;
}

/**
 * TutorialLtdApi - object-oriented interface
 * @export
 * @class TutorialLtdApi
 * @extends {BaseAPI}
 */
export class TutorialLtdApi extends BaseAPI {
  /**
   *
   * @summary Get Tutorial LTD State
   * @param {TutorialLtdApiGetTutorialLtdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TutorialLtdApi
   */
  public getTutorialLtd(
    requestParameters: TutorialLtdApiGetTutorialLtdRequest,
    options?: any,
  ) {
    return TutorialLtdApiFp(this.configuration)
      .getTutorialLtd(requestParameters.playerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post Tutorial Ltd State
   * @param {TutorialLtdApiPostTutorialLtdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TutorialLtdApi
   */
  public postTutorialLtd(
    requestParameters: TutorialLtdApiPostTutorialLtdRequest,
    options?: any,
  ) {
    return TutorialLtdApiFp(this.configuration)
      .postTutorialLtd(
        requestParameters.playerId,
        requestParameters.tutorial,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
