import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useUnit } from "effector-react";
import { useUrlState } from "src/shared/hooks/useUrlState";
import {
  BaseButton,
  BaseInputNumber,
  BasePopup,
  EPopupName,
} from "../../../shared/components";
import {
  $assessmentsListAdmin,
  setAssessmentsListAdminIsReadmore,
  cleanAssessmentsListAdmin,
  requestAssessmentsListAdmin,
  IRequestAssessmentsListAdmin,
} from "src/entities/public/assessments-list-admin";
import { ERequestStatus } from "../../../shared/store/types";
import { PageWrapper } from "src/shared/components";
import { AssessmentStatus, AssessmentType } from "../../../generated/game";
import { TDataGridDataParams } from "../../../shared/models/dataGrid";
import isEqual from "lodash/isEqual";
import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";
import "./planed-page.scss";
import { PlanedAssessmentsTable } from "../../../shared/components/data-grids/planed-page/planed-assessments-table";
import { openAddAssessmentPopup } from "../../../features/hr/assessments/lib/openPopups";
import { assessmentsModel } from "src/entities/public/assessments";

import { simulationsModel } from "src/entities/admin/simulations";
import { exportAssessments } from "src/entities/public/assessments/model";
import {
  $activePopups,
  closePopup,
} from "src/shared/components/base-popup/model";
import { copyAssessment } from "src/entities/hr/assessment-create";

interface Props {}

export const PlanedPage: FC<Props> = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    items: assessments,
    pagination,
    status: assessmentsStatus,
  } = useUnit($assessmentsListAdmin);

  const { fetchAssessments, resetAssessments } = assessmentsModel;

  const { fetchSimulations, resetSimulations } = simulationsModel;

  const [urlState, setUrlState] = useUrlState();

  const [checkList, setCheckList] = useState<string[]>([]);

  const [copyCount, setCopyCount] = useState<number | null>(null);

  const isAssessmentsLoading: boolean =
    assessmentsStatus === ERequestStatus.LOADING;

  const assessmentTypes = useMemo<AssessmentType[]>(
    () => Object.values(AssessmentType),
    [],
  );

  useEffect(() => {
    fetchAssessments({ assessmentStatusQuery: [AssessmentStatus.Lobby] });

    fetchSimulations();

    return () => {
      resetAssessments();

      resetSimulations();
    };
  }, [fetchAssessments, fetchSimulations, resetAssessments, resetSimulations]);

  const requestAssessmentsParams: IRequestAssessmentsListAdmin = useMemo(() => {
    const {
      pageNum,
      pageSize,
      orderBy,
      title,
      gameTitle,
      curPlayersFrom,
      curPlayersTo,
      closedSession,
    } = urlState.query;

    let assessmentTypeValues: AssessmentType[] = urlState.query.assessmentType
      ? (urlState.query.assessmentType.split(",") as AssessmentType[])
      : [];

    assessmentTypeValues = assessmentTypeValues.filter((assessmentTypeValue) =>
      assessmentTypes.includes(assessmentTypeValue),
    );

    let gameTypeValues = (urlState.query.gameType?.split(",") ?? []) as Array<
      "single" | "solo" | "team" | "corp"
    >;

    return {
      params: {
        localeQuery: "ru",
        assessmentStatusQuery: [AssessmentStatus.Lobby],
        pageSize: pageSize ? Number(pageSize) : 50,
        pageNum: pageNum ? Number(pageNum) : 1,
        orderByQuery: orderBy,
        titleQuery: title ? [title] : undefined,
        gameTitleQuery: gameTitle ? [gameTitle] : undefined,
        assessmentTypeQuery: !!assessmentTypeValues.length
          ? assessmentTypeValues
          : undefined,
        gameTypeQuery: !!gameTypeValues.length ? gameTypeValues : undefined,
        curPlayersFromQuery: curPlayersFrom
          ? Number(curPlayersFrom)
          : undefined,
        curPlayersToQuery: curPlayersTo ? Number(curPlayersTo) : undefined,
        closedSession: closedSession ? closedSession === "true" : undefined,
      },
    };
  }, [assessmentTypes, urlState.query]);

  useEffect(() => {
    requestAssessmentsListAdmin(requestAssessmentsParams);
  }, [requestAssessmentsParams]);

  useEffect(() => {
    return () => {
      cleanAssessmentsListAdmin();
    };
  }, [dispatch]);

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      if (!isEqual(urlState.query, newQueryParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newQueryParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  const goToPage = (pageNum: number, isReadmoreValue: boolean = false) => {
    setAssessmentsListAdminIsReadmore({ value: isReadmoreValue });

    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const exportLinks = () => {
    exportAssessments({
      assessmentIds: checkList,
    });
  };

  const cancelExport = () => {
    setCheckList([]);
  };

  const activePopups = useUnit($activePopups);

  const copiesCreate = () => {
    const assessmentId = activePopups.find(
      ({ name }) => name === EPopupName.COPY_SESSION_POPUP,
    )?.data.id;

    if (copyCount && assessmentId) {
      copyAssessment({
        params: {
          assessmentId,
          assessmentCopyReqDtoBody: {
            num_copy: copyCount,
          },
        },
        refetchParams: requestAssessmentsParams,
      });
    }

    closePopup({ name: EPopupName.COPY_SESSION_POPUP });
  };

  return (
    <PageWrapper
      title={t("assessmentsControl.title")}
      isLoadingPanel={isAssessmentsLoading}
      isLightBlueBackground
      isShowContentWhenIsLoading
      controlPanelTitle={t("assessmentsControl.controlPanel.label.assessments")}
      controlPanelMaxCount={pagination?.totalItems}
      titlePanelSlot={
        <div className="table__control">
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileSortButton />
          </div>
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileFiltersButton />
          </div>
        </div>
      }
      controlPanelSlot={
        <div className="created-assessments-controls">
          {!!checkList.length && (
            <>
              <BaseButton small secondary onClick={cancelExport}>
                {t("common.cancel")}
              </BaseButton>
              <BaseButton small outline onClick={exportLinks}>
                {t("assessment.export")}
              </BaseButton>
            </>
          )}
          <BaseButton small primary onClick={openAddAssessmentPopup}>
            {t("usersControl.controlPanel.btn.newGame")}
          </BaseButton>
        </div>
      }
    >
      <div className="created-assessments-page">
        <div className="created-assessments-data-grid-wrapper">
          <PlanedAssessmentsTable
            assessmentItems={assessments}
            queryParams={urlState.query}
            loading={isAssessmentsLoading}
            onUpdateSortAndFilters={onUpdateQueryParams}
            pagination={pagination}
            goToPage={(pageNum) => goToPage(pageNum)}
            readmore={(pageNum) => goToPage(pageNum, true)}
            selectedRowsIds={checkList}
            onSelectRows={setCheckList}
          />
        </div>
      </div>

      <BasePopup
        name={EPopupName.COPY_SESSION_POPUP}
        title={t("session.popup.copy.title")}
        closeButton={false}
        className="copy-assessment-popup"
      >
        <BaseInputNumber
          value={String(copyCount)}
          onChange={(event) => setCopyCount(Number(event.value))}
          placeholder={t("session.popup.number.copy")}
        />

        <BaseButton primary onClick={copiesCreate}>
          {t("session.popup.copy.create")}
        </BaseButton>
      </BasePopup>
    </PageWrapper>
  );
};
