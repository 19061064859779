import { Provider } from "react-redux";
import TagManager from "react-gtm-module";
import { config } from "./config/config";
import { store } from "./shared/store/store";
import { initializeAxios } from "./shared/api/api";
import type { KeycloakOnLoad } from "keycloak-js";

import "ts-replace-all";

import smoothscroll from "smoothscroll-polyfill";
import { createRoot } from "react-dom/client";
import React from "react";
import { AppRoutes } from "./app/providers/router/routes";

smoothscroll.polyfill();

if (!!process.env.REACT_APP_GTM) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM,
  });
}

let initOptions: {
  url: string;
  realm: string;
  clientId: string;
  onLoad: KeycloakOnLoad;
} = {
  url: config.kc.url,
  realm: config.kc.realm,
  clientId: config.kc.clientId,
  onLoad: "login-required",
};

let loadKeycloak = async () => {
  const module = await import("keycloak-js");

  return new module.default(initOptions);
};

loadKeycloak().then((keycloak) => {
  keycloak
    .init({ onLoad: initOptions.onLoad })
    .then((auth: boolean) => {
      if (!auth) {
        window.location.reload();
      } else {
        console.log("Authenticated");

        if (keycloak.token && keycloak.refreshToken) {
          localStorage.setItem("alabuga-token", keycloak.token);
          localStorage.setItem("alabuga-refresh-token", keycloak.refreshToken);
          initializeAxios();
        }

        const container = document.getElementById("root");
        const root = createRoot(container!);

        root.render(
          <>
            <Provider store={store}>
              <AppRoutes keycloak={keycloak} />
            </Provider>
          </>,
        );
      }

      // Token Refresh
      setInterval(() => {
        keycloak
          .updateToken(180)
          .then((refreshed: boolean) => {
            if (refreshed) {
              if (keycloak.token && keycloak.refreshToken) {
                localStorage.setItem("alabuga-token", keycloak.token);
                localStorage.setItem(
                  "alabuga-refresh-token",
                  keycloak.refreshToken,
                );
              }
              console.log("Token refreshed");
            }
          })
          .catch(() => {
            console.error("Failed to refresh token");
          });
      }, 30000);
    })
    .catch(() => {
      console.error("Authenticated Failed");
    });
});
